import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageContainer from '../../../Common/Pages/PageContainer';
import styles from './RootAssessmentClone.module.css';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import CustomButton from '../../../Common/Components/CustomButton';
import InfoBanner from '../../../Common/Components/InfoBanner';
import {
  getCompanyListAction,
  getRootAssessmentCloneAction,
  copyRootAssessmentAction
} from '../../Api/actions';
import {
  infoBannerShowStateSelector,
  infoBannerTextSelector,
  infoBannerErrorFlagSelector
} from '../../../redux/assessmentDetailVol2/selectors';
import {
  setInfoBannerShowState,
  setInfoBannerText,
  setInfoBannerErrorFlag
} from '../../../redux/assessmentDetailVol2/slice';

const RootAssessmentClone = () => {
  const dispatch = useDispatch();
  const infoBannerShowStateRedux = useSelector(infoBannerShowStateSelector);
  const infoBannerTextRedux = useSelector(infoBannerTextSelector);
  const infoBannerErrorFlagRedux = useSelector(infoBannerErrorFlagSelector);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [targetCompany, setTargetCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    setTests([]);
    setSelectedTest(null);
    if (selectedCompany) {
      fetchCompanyTests(selectedCompany.id);
    } else {
      setTests([]);
    }
  }, [selectedCompany]);

  const fetchCompanies = async () => {
    const resp = await getCompanyListAction();
    if (resp.data) {
      const companies = resp.data.map(company => {
        return {
          id: company.id,
          name: company.name
        };
      });
      setCompanies(companies);
    }
  };

  const fetchCompanyTests = async companyId => {
    const resp = await getRootAssessmentCloneAction({ companyId });
    if (resp.data) {
      const tests = resp.data.map(test => {
        return {
          id: test.rootAssessmentId,
          name: test.rootAssessmentName,
          totalTime: test.rootAssessmentTotalTime,
          questionCount: test.rootAssessmentQuestionCount
        };
      });
      setTests(tests.reverse());
    }
  };

  const handleCloneRootAssessment = async () => {
    setLoading(true);
    await copyRootAssessmentAction({
      companyId: targetCompany.id,
      rootAssessmentId: selectedTest.id
    })
      .then(res => {
        if (res.data) {
          dispatch(setInfoBannerText('Test başarıyla klonlandı.'));
          dispatch(setInfoBannerShowState(true));
        }
      })
      .catch(error => {
        const errorMessage = error.response?.data?.message || 'Beklenmeyen bir hata oluştu.';
        dispatch(setInfoBannerShowState(true));
        dispatch(setInfoBannerText(errorMessage));
        dispatch(setInfoBannerErrorFlag(true));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formatTime = totalSeconds => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    if (minutes === 0) {
      return `${seconds} seconds`;
    }
    if (seconds === 0) {
      return `${minutes} minutes`;
    }
    return `${minutes} minutes ${seconds} seconds`;
  };

  return (
    <PageContainer>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.upperRow}>
            <Dropdown
              title="Company"
              placeholder="Select Company"
              searchBarPlaceholder="Search Company"
              list={companies}
              openItemCount={6}
              selectedOption={setSelectedCompany}
              setSelectedOption={_ => setSelectedCompany(_)}
              isSearchable
              height="5rem"
              wrapperStyle={{ width: '300px' }}
            />
            <Dropdown
              title="Test"
              placeholder="Select Test"
              searchBarPlaceholder="Search Test"
              list={tests}
              openItemCount={6}
              selectedOption={selectedTest}
              setSelectedOption={_ => setSelectedTest(_)}
              isSearchable
              height="5rem"
              wrapperStyle={{ width: '300px' }}
            />
            <Dropdown
              title="Target Company"
              placeholder="Select Company"
              searchBarPlaceholder="Search Company"
              list={companies.filter(company => company.id !== selectedCompany?.id)}
              openItemCount={6}
              selectedOption={targetCompany}
              setSelectedOption={_ => setTargetCompany(_)}
              isSearchable
              disabled={!selectedTest}
              height="5rem"
              wrapperStyle={{ width: '300px' }}
            />
          </div>
          <div className={styles.timeCloneContainer}>
            <div>{selectedTest && <div>Total Time: {formatTime(selectedTest.totalTime)}</div>}</div>
            <div>
              <CustomButton
                textField="Clone"
                type={1}
                loading={loading}
                buttonOnClick={() => {
                  handleCloneRootAssessment();
                }}
                isDisabled={
                  !targetCompany ||
                  !selectedTest ||
                  loading ||
                  targetCompany.id === selectedCompany ||
                  !selectedCompany ||
                  selectedTest.questionCount === 0
                }
              />
            </div>
          </div>
        </div>
      </div>

      <InfoBanner
        text={infoBannerTextRedux}
        show={infoBannerShowStateRedux}
        CustomIcon={infoBannerErrorFlagRedux ? Error : null}
        setStatus={() => {
          dispatch(setInfoBannerShowState(false));
          dispatch(setInfoBannerText());
          dispatch(setInfoBannerErrorFlag(false));
        }}
        bannerStyle={{
          border: infoBannerErrorFlagRedux
            ? '1px solid rgba(221, 58, 58, 0.1)'
            : '1px solid rgba(74, 195, 137, 0.1)'
        }}
      />
    </PageContainer>
  );
};

export default RootAssessmentClone;
