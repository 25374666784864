import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { getFilteredUserIdAction } from '../../../redux/candidateDetail/actions';
import CandidateDetailComponent from '../../Components/CandidateDetail/CandidateDetailComponent';
import styles from './CandidateDetailPage.module.css';

import goBackArrow from './goBackArrow.svg';
import lightLeft from './leftScrollLight.svg';
import left from './leftScroll.svg';
import lightright from './rightScrollLight.svg';
import right from './rightScroll.svg';
import { userSelector } from '../../../redux/auth/selectors';
import {
  candidatePositionsSelector,
  candidateProfileSelector,
  loadingForPersonalInfoSelector,
  personalInfoExperienceSelector,
  personalInfoNationalitySelector,
  personalInfoSalarySelector,
  personalInfoWorkTypeSelector
} from '../../../redux/candidateDetail/selectors';
import { resetToInitial } from '../../../redux/candidateDetail/slice';
import { genericMixpanelCandidateDetailEventGenerator } from '../../Components/CandidateDetail/helpers';
import { getParam } from '../../../utils/helpers';
import PageContainer from '../../../Common/Pages/PageContainer';
import constants from '../../../utils/constants';

const CandidateDetailPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const [nextCandId, setNextCandId] = useState();
  const [prevCandId, setPrevCandId] = useState();
  const [candSearchFlag, setCandSearchFlag] = useState();

  async function fetchNextAndPrevCandidates() {
    const candidateSearchFlag = getParam(location.search, 'candidateSearchFlag');

    if (candidateSearchFlag) {
      setCandSearchFlag(true);
      return;
    }

    const searchParamUrl = getParam(location.search, 'searchParam');
    const orderTypeUrl = getParam(location.search, 'orderType');
    const funnelIdUrl = getParam(location.search, 'funnelId');
    const startDateUrl = getParam(location.search, 'startDate');
    const endDateUrl = getParam(location.search, 'endDate');
    const startScoreUrl = getParam(location.search, 'startScore');
    const endScoreUrl = getParam(location.search, 'endScore');
    // const pageNumberUrl = getParam(location.search, 'pageNumber');
    const arriveTypeUrl = getParam(location.search, 'arriveType');

    const industryListUrl = getParam(location.search, 'industryList');
    const jobTitleListUrl = getParam(location.search, 'jobTitleList');
    const schoolListUrl = getParam(location.search, 'schoolList');
    const majorListUrl = getParam(location.search, 'majorList');
    const industryIncludeTypeUrl = getParam(location.search, 'industryIncludeType');
    const jobTitleIncludeTypeUrl = getParam(location.search, 'jobTitleIncludeType');
    const experiencesUrl = getParam(location.search, 'experiences');
    const workTypesUrl = getParam(location.search, 'workTypes');
    const nationalitiesUrl = getParam(location.search, 'workPermit');
    const locationsUrl = getParam(location.search, 'location');
    const currencyUrl = getParam(location.search, 'currency');
    const minSalaryUrl = getParam(location.search, 'minSalary');
    const maxSalaryUrl = getParam(location.search, 'maxSalary');

    const rejectReasonUrl = getParam(location.search, 'rejectReason');

    let industryListData;
    const industryIncludeTypeData = parseInt(industryIncludeTypeUrl, 10);
    let jobTitleListData;
    const jobTitleIncludeTypeData = parseInt(jobTitleIncludeTypeUrl, 10);
    let schoolListData;
    let majorListData;
    let screeningExperiencesData;
    let screeningWorkTypesData;
    let screeningNationalitiesData;
    let screeningLocationsData;
    let rejectReasonData;
    let arriveTypeData;

    const screeningSalaryCurrencyData = parseInt(currencyUrl, 10);
    const screeningSalaryMinValueData = parseInt(minSalaryUrl, 10) || null;
    const screeningSalaryMaxValueData = parseInt(maxSalaryUrl, 10) || null;

    if (industryListUrl) {
      industryListData = [];
      // industryListUrl.split(',')?.forEach(x => industryListData.push(parseInt(x, 10)));
      industryListUrl.split(',')?.forEach(x => industryListData.push(decodeURIComponent(x)));
    }

    if (jobTitleListUrl) {
      jobTitleListData = [];
      // jobTitleListUrl.split(',')?.forEach(x => industryListData.push(parseInt(x, 10)));
      jobTitleListUrl.split(',')?.forEach(x => jobTitleListData.push(decodeURIComponent(x)));
    }

    if (schoolListUrl) {
      schoolListData = [];
      // schoolListUrl.split(',')?.forEach(x => schoolListData.push(parseInt(x, 10)));
      schoolListUrl.split(',')?.forEach(x => schoolListData.push(decodeURIComponent(x)));
    }

    if (majorListUrl) {
      majorListData = [];
      // schoolListUrl.split(',')?.forEach(x => schoolListData.push(parseInt(x, 10)));
      majorListUrl.split('--')?.forEach(x => majorListData.push(decodeURIComponent(x)));
    }

    if (experiencesUrl) {
      screeningExperiencesData = [];
      experiencesUrl.split(',')?.forEach(x => screeningExperiencesData.push(parseInt(x, 10)));
    }

    if (workTypesUrl) {
      screeningWorkTypesData = [];
      workTypesUrl.split(',')?.forEach(x => screeningWorkTypesData.push(parseInt(x, 10)));
    }

    if (nationalitiesUrl) {
      screeningNationalitiesData = [];
      nationalitiesUrl.split(',')?.forEach(x => screeningNationalitiesData.push(parseInt(x, 10)));
    }

    if (locationsUrl) {
      screeningLocationsData = [];
      locationsUrl.split(',')?.forEach(x => screeningLocationsData.push(parseInt(x, 10)));
    }

    if (rejectReasonUrl) {
      rejectReasonData = [];
      rejectReasonUrl.split(',')?.forEach(x => rejectReasonData.push(x));
    }

    if (arriveTypeUrl) {
      arriveTypeData = [];
      arriveTypeUrl.split(',')?.forEach(x => arriveTypeData.push(parseInt(x, 10)));
    }

    const data = {
      candidateId: id,
      companyAssessmentId: getParam(location.search, 'defaultAssessmentId'),
      startDate: parseInt(startDateUrl, 10) || (parseInt(startDateUrl, 10) === 0 ? 0 : null),
      endDate: parseInt(endDateUrl, 10) || 0,
      startScore: parseInt(startScoreUrl, 10) || 0,
      endScore: parseInt(endScoreUrl, 10) || 100,
      funnelId: funnelIdUrl,
      orderType: parseInt(orderTypeUrl, 10) || 3,
      search: decodeURIComponent(searchParamUrl),
      arriveTypes:
        arriveTypeData?.length > 0
          ? arriveTypeData.map(x => constants.SAVE_FILTER_ARRIVE_TYPE_INDEX_MAPPING[x]).flat()
          : null,
      pageNumber: 0,
      items: 0,
      schoolList: schoolListData || null,

      majorList: majorListData || null,

      industry: industryListData
        ? {
            industryList: industryListData,
            industryInclude: industryIncludeTypeData
          }
        : null,

      jobTitle: jobTitleListData
        ? {
            jobTitleList: jobTitleListData,
            jobTitleInclude: jobTitleIncludeTypeData
          }
        : null,
      screening:
        screeningLocationsData ||
        screeningExperiencesData ||
        screeningWorkTypesData ||
        screeningNationalitiesData ||
        screeningSalaryMinValueData ||
        screeningSalaryMaxValueData
          ? {
              experiences: screeningExperiencesData,
              workTypes: screeningWorkTypesData,
              nationalities: screeningNationalitiesData,
              locationIds: screeningLocationsData,

              salary:
                screeningSalaryMinValueData || screeningSalaryMaxValueData
                  ? {
                      currency: screeningSalaryCurrencyData,
                      minSalary: screeningSalaryMinValueData || 0,
                      maxSalary: screeningSalaryMaxValueData || 1000000
                    }
                  : null
            }
          : null,

      rejectReason: rejectReasonData || null
    };

    const resp = await dispatch(getFilteredUserIdAction(data));
    setNextCandId(resp?.payload?.nextUserId);
    setPrevCandId(resp?.payload?.previousUserId);
  }

  useEffect(() => {
    fetchNextAndPrevCandidates();

    return () => {
      dispatch(resetToInitial());
    };
  }, []);

  // Mixpanel Candidate Detail - Pageview
  // #region
  const currentUser = useSelector(userSelector);
  const positionsList = useSelector(candidatePositionsSelector);
  const candidateProfile = useSelector(candidateProfileSelector);
  const loadingForPersonalInfoRedux = useSelector(loadingForPersonalInfoSelector);
  const personalInfoExperienceRedux = useSelector(personalInfoExperienceSelector);
  const personalInfoNationalityRedux = useSelector(personalInfoNationalitySelector);
  const personalInfoSalaryRedux = useSelector(personalInfoSalarySelector);
  const personalInfoWorkTypeRedux = useSelector(personalInfoWorkTypeSelector);

  useEffect(() => {
    if (
      currentUser &&
      positionsList &&
      positionsList?.length > 0 &&
      candidateProfile &&
      !loadingForPersonalInfoRedux
    ) {
      genericMixpanelCandidateDetailEventGenerator(
        'Candidate Detail - Pageview',
        currentUser,
        positionsList,
        candidateProfile,
        loadingForPersonalInfoRedux,
        personalInfoExperienceRedux,
        personalInfoNationalityRedux,
        personalInfoSalaryRedux,
        personalInfoWorkTypeRedux
      );
    }
  }, [currentUser, positionsList, candidateProfile, loadingForPersonalInfoRedux]);

  // #endregion

  return (
    <PageContainer>
      <CandidateDetailNavigation
        prevCandId={prevCandId}
        nextCandId={nextCandId}
        candSearchFlag={candSearchFlag}
      />
      <CandidateDetailComponent />
    </PageContainer>
  );
};
export default CandidateDetailPage;

const CandidateDetailNavigation = ({ prevCandId, nextCandId, candSearchFlag }) => {
  function goBackBehavior() {
    const isCopiedBefore = getParam(window.location.href, 'copied');
    const isDirectedFromCandidateSearch = candSearchFlag;

    if (isDirectedFromCandidateSearch) {
      const host = window.location.origin;
      const defaultAssessmentId = getParam(window.location.href, 'defaultAssessmentId');
      const assessDetUrl = `${host}/assessment-detail/${defaultAssessmentId}`;
      window.location.replace(assessDetUrl);
    } else if (!isCopiedBefore) {
      history.back();
    } else {
      const host = window.location.origin;
      const defaultAssessId = getParam(window.location.href, 'defaultAssessmentId');
      const allParams = window.location.search;

      const currentAssessId = getParam(window.location.href, 'currentAssessmentId');
      let requiredParams = allParams
        .split(`currentAssessmentId=${currentAssessId}`)[1]
        .substring(1);
      // Remove copied flag
      requiredParams = requiredParams.split('&copied=true')[0];

      const assessDetUrl = `${host}/assessment-detail/${defaultAssessId}?${requiredParams}`;
      window.location.replace(assessDetUrl);
    }
  }

  function switchToPreviousCandidate() {
    if (prevCandId) {
      window.location.replace(`/candidate-detail/${prevCandId}${location.search}`);
    }
  }

  function switchToNextCandidate() {
    if (nextCandId) {
      window.location.replace(`/candidate-detail/${nextCandId}${location.search}`);
    }
  }

  const [onCurrentPosition, setOnCurrentPosition] = useState(false);

  function updateStatesOnAssessmentChange() {
    const currentAssessId = getParam(location.search, 'currentAssessmentId');
    const defAssessId = getParam(location.search, 'defaultAssessmentId');
    if (currentAssessId === defAssessId) {
      setOnCurrentPosition(true);
    } else {
      setOnCurrentPosition(false);
    }
  }

  const { t } = useTranslation(['candidateDetail']);

  useEffect(() => {
    updateStatesOnAssessmentChange();
    window.addEventListener('locationchange', updateStatesOnAssessmentChange);
    return () => {
      window.removeEventListener('locationchange', updateStatesOnAssessmentChange);
    };
  }, []);

  return (
    <div className={styles.ButtonRow}>
      <div className={styles.GoBackWrapper}>
        <img
          src={goBackArrow}
          className={styles.GoBackButton}
          alt="goBack"
          onClick={() => goBackBehavior()}
        />
        {t('candidateProfile')}
      </div>
      {!candSearchFlag ? (
        <div className={styles.NextPrevWrapper}>
          <img
            src={prevCandId && onCurrentPosition ? left : lightLeft}
            alt="previousCandidate"
            className={styles.NextPrevButton}
            style={
              prevCandId && onCurrentPosition ? { cursor: 'pointer' } : { pointerEvents: 'none' }
            }
            onClick={() => switchToPreviousCandidate()}
          />
          <img
            src={nextCandId && onCurrentPosition ? right : lightright}
            alt="nextCandidate"
            className={styles.NextPrevButton}
            style={
              nextCandId && onCurrentPosition ? { cursor: 'pointer' } : { pointerEvents: 'none' }
            }
            onClick={() => switchToNextCandidate()}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
