import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import QuestionHeader from '../../Components/QuestionHeader/QuestionHeader';
import styles from './QuestionPage.module.css';
import Timer from '../../Components/Timer/Timer';
import {
  ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD,
  ASSESSMENT_FLOW_MOBILE_THRESHOLD,
  base64ToBlob,
  useWindowSize
} from '../../../../utils/helpers';
import MultipleChoiceQuestion from '../../Components/MultipleChoiceQuestion/multipleChoiceQuestion';
import {
  ShowBanner,
  questionInfoRedux,
  BannerText,
  // CompanyName,
  CandidateEmail,
  // AssessmentTitle,
  isCameraNeededRedux
} from '../../../../redux/AssessmentSession/selectors';
import {
  getCurrentQuestionAction,
  getNextQuestionAction,
  saveCurrentCodingAnswerAction,
  saveCurrentFreeTextAnswerAction,
  saveCurrentMultipleChoiceAnswerAction,
  incrementTabSwitchAction,
  submitCodingQuestionAnswerAction,
  submitFreeTextQuestionAnswerAction,
  submitMultipleChoiceQuestionAnswerAction,
  submitMultiCorrectAnswerAction
} from '../../../../redux/AssessmentSession/actions';
import FreeTextQuestion from '../../Components/FreeTextQuestion/freeTextQuestion';
import CodingQuestion from '../../Components/CodingQuestion/codingQuestion';
import MultiCorrect from '../../Components/MultipleCorrectQuestion/multipleCorrectQuestion';
import { useInterval } from '../../../../utils/useInterval';
import InfoBanner from '../../../../Common/Components/InfoBanner';
import {
  resetQuestionInfo,
  setBannerStatus,
  setBannerText
} from '../../../../redux/AssessmentSession/slice';
import { ReactComponent as Error } from '../../../../images/Common/errorSubmit.svg';
import useCameraHandler from '../../Utils/useCameraHandler';
import { saveCameraShotAction } from '../../Api/actions';
import useDisableOnTranslation from '../../Utils/useDisableOnTranslation';

const QuestionPage = () => {
  const { t, i18n } = useTranslation(['application']);

  const dispatch = useDispatch();
  const qInfo = useSelector(questionInfoRedux);

  const SAVE_INTERVAL = 10000;

  const { search } = useLocation();
  const assessmentId = new URLSearchParams(search).get('assessment');
  const history = useHistory();
  const [screenWidth] = useWindowSize();
  const [headersHeight, setHeadersHeight] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [ftAnswer, setFtAnswer] = useState('');
  const [codingAnswer, setCodingAnswer] = useState('');
  const [codeLanguage, setCodeLanguage] = useState('');
  const [selectedLangIndex, setSelectedLangIndex] = useState(-1);
  const showBanner = useSelector(ShowBanner);
  const bannerText = useSelector(BannerText);
  const [qDescription, setqDescription] = useState();
  const [error, setError] = useState(false);
  const [timerLoading, setTimerLoading] = useState(false);

  const updateAnswerForPhp = code => {
    let ret = code;
    ret = ret.replace('<?php ', '');
    ret = ret.replace('?>', '');
    return ret;
  };
  
  // Next ve submit tıklandığında çağrılıyor
  const submitQuestion = async () => {
    let data = {};
    let resp;
    switch (qInfo.questionTypeId) {
      case 1:
        data = {
          questionId: qInfo.questionId,
          optionIndex: selectedIndex,
          isFinalized: true
        };
        resp = await dispatch(submitMultipleChoiceQuestionAnswerAction(data));
        if (resp.type === 'submitMultipleChoiceQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      case 3:
        data = {
          questionId: qInfo.questionId,
          answerText: ftAnswer || '',
          isFinalized: true
        };
        resp = await dispatch(submitFreeTextQuestionAnswerAction(data));
        if (resp.type === 'submitFreeTextQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      case 2:
        data = {
          questionId: qInfo.questionId,
          answerCode:
            codeLanguage?.label === 'PHP' ? updateAnswerForPhp(codingAnswer) : codingAnswer || '',
          answerCodeLanguages: codeLanguage?.label || 'notSelected',
          isFinalized: true
        };
        resp = await dispatch(submitCodingQuestionAnswerAction(data));
        if (resp.type === 'submitCodingQuestionAnswer/fulfilled') {
          return true;
        }
        break;

      case 4:
        data = {
          questionId: qInfo.questionId,
          optionAnswers: selectedIndexes,
          isFinalized: true
        };
        resp = await dispatch(submitMultiCorrectAnswerAction(data));

        if (resp.type === 'submitMultiCorrectAnswer/fulfilled') {
          setSelectedIndexes([]);
          return true;
        }
        break;

      default:
        break;
    }
    return false;
  };

  // Belirli aralıklarla kullanıcı cevaplarını kaydediyor
  const saveAnswer = () => {
    let data = {};
    switch (qInfo.questionTypeId) {
      case 1:
        data = {
          questionId: qInfo.questionId,
          optionIndex: selectedIndex,
          isFinalized: false
        };
        dispatch(saveCurrentMultipleChoiceAnswerAction(data));
        break;

      case 3:
        data = {
          questionId: qInfo.questionId,
          answerText: ftAnswer || '',
          isFinalized: false
        };
        dispatch(saveCurrentFreeTextAnswerAction(data));
        break;

      case 2:
        data = {
          questionId: qInfo.questionId,
          answerCode: codingAnswer || '',
          // answerCode: codeLanguage?.label === "PHP" ? updateAnswerForPhp(codingAnswer) : (codingAnswer || ''),
          answerCodeLanguages: codeLanguage?.label || '',
          isFinalized: false
        };
        dispatch(saveCurrentCodingAnswerAction(data));
        break;

      case 4:
        data = {
          questionId: qInfo.questionId,
          optionAnswers: selectedIndexes,
          isFinalized: false
        };
        dispatch(submitMultiCorrectAnswerAction(data));
        break;

      default:
        break;
    }
  };

  useInterval(
    () => {
      saveAnswer();
    },
    SAVE_INTERVAL,
    qInfo
  );

  // Görseller için path düzenlemesi
  const fixDescription = () => {
    try {
      let questionBody = JSON.parse(qInfo?.descHtmlJson);
      questionBody = questionBody.replaceAll('https://api.coens.io/', '');
      questionBody = questionBody.replaceAll('https://file.coens.io/', '');
      questionBody = questionBody.replaceAll(
        '<img src="',
        `<img src="${process.env.REACT_APP_IMAGE_API}/`
      );
      questionBody = questionBody.replaceAll(
        '<source src="',
        `<source src="${process.env.REACT_APP_IMAGE_API}/`
      );
      setqDescription(questionBody);
    } catch (error) {
      console.error(error);
      setqDescription();
    }
  };

  // Header ve timer componentlarının yüksekliklerini alıyor
  const calculateUpperHeight = () => {
    setTimeout(() => {
      const hHeight = document.getElementById('qHeader')?.getBoundingClientRect().height + 1;
      const tHeight = document.getElementById('qTimer')?.getBoundingClientRect().height + 1;
      setHeadersHeight(hHeight + tHeight);
    }, 200);
  };

  // Kodlama soruları - eğer kullanıcının kayıtlı datası varsa onların getirtilmesi için
  const [readSavedData, setReadSavedData] = useState(false);

  useEffect(async () => {
    // Sayfa yenilendiğinde current question bilgisini çekiyor
    if (!qInfo) {
      await dispatch(getCurrentQuestionAction());
    }
    calculateUpperHeight();
    if (qInfo) {
      fixDescription();

      if (qInfo?.lastMultipleChoiceAnswer || qInfo?.lastMultipleChoiceAnswer === 0) {
        setSelectedIndex(qInfo.lastMultipleChoiceAnswer);
      } else {
        setSelectedIndex(-1);
      }

      if (qInfo?.lastFreeTextAnswer) {
        setFtAnswer(qInfo.lastFreeTextAnswer);
      } else {
        setFtAnswer('');
      }

      if (qInfo?.lastCodingLanguage) {
        setReadSavedData(true);
        setCodeLanguage({ value: qInfo.lastCodingLanguage, label: qInfo.lastCodingLanguage });
      } else {
        setCodeLanguage('');
      }

      if (qInfo?.lastCodingAnswer) {
        setReadSavedData(true);
        setCodingAnswer(qInfo.lastCodingAnswer);
      } else {
        setCodingAnswer('');
      }

      // Gelen kodlama sorusu sadece tek dil ile çözülebiliyorsa onu seçili getir
      if (qInfo?.language?.length === 1) {
        setSelectedLangIndex(0);
        // Dropdown için hazırlık
        setCodeLanguage({ value: qInfo.language[0], label: qInfo.language[0] });
      } else {
        setSelectedLangIndex(-1);
      }
    }
  }, [qInfo]);

  const onNextClick = async () => {
    trigger();
    const bool = await submitQuestion();
    setTimerLoading(true);
    if (bool) {
      setError(false);
      setSelectedLangIndex(-1);
      await dispatch(getNextQuestionAction());
      setTimerLoading(false);
    } else {
      dispatch(setBannerText(t('submissionError')));
      setError(true);
      dispatch(setBannerStatus(true));
      setTimerLoading(false);
    }
  };

  function cancelFullscreen() {
    const doc = window.document;

    const cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    ) {
      cancelFullScreen.call(doc);
    }
  }

  const onSubmitClick = async () => {
    trigger();
    const bool = await submitQuestion();
    if (bool) {
      try {
        cancelFullscreen();
      } catch (error) {
        console.error('Fullscreen error', error);
      }
      history.replace(`candidate-result?assessment=${assessmentId}`);
      setError(false);
    } else {
      dispatch(setBannerText(t('submissionError')));
      setError(true);
      dispatch(setBannerStatus(true));
    }
  };

  useEffect(() => {
    if (!readSavedData) {
      if (selectedLangIndex !== -1) {
        if (qInfo?.initialCodeText) {
          setCodingAnswer(qInfo.initialCodeText[selectedLangIndex]);
        }
      }
    }
    setReadSavedData(false);
  }, [selectedLangIndex]);

  const resetToInitialCode = () => {
    setCodingAnswer(qInfo.initialCodeText[selectedLangIndex]);
  };

  /* Assessment rules */

  /* Prevents right click for the assesssment page */
  const handleContextMenu = event => {
    event.preventDefault();
  };

  /* Increment the counter every time there is a switch */
  const handleTabSwitch = async () => {
    await dispatch(incrementTabSwitchAction({}));
  };
  /* Forwards the history state FIXME: could be buggy */
  const blockBackButton = event => {
    dispatch(resetQuestionInfo());
    event.preventDefault();
    history.go(1);
  };

  useEffect(() => {
    /* Prevents right click for the assesssment page */
    document.addEventListener('contextmenu', handleContextMenu);
    /* Fires every time visibility of the body changes */
    window.addEventListener('blur', handleTabSwitch);
    /* Prevent back button by going forward */
    window.onpopstate = blockBackButton;

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('blur', handleTabSwitch);
    };
  }, []);

  /* ------------------------- Camera stuff -------------------------*/
  const candidateMailRedux = useSelector(CandidateEmail);
  const isCameraNeeded = useSelector(isCameraNeededRedux);

  function onCapture(imgSrc) {
    const formData = new FormData();
    formData.append('email', candidateMailRedux);
    formData.append('companyAssessmentKey', assessmentId);
    if (imgSrc) {
      const base64part = imgSrc.split('data:image/png;base64,')[1];
      const blob = base64ToBlob(base64part, 'image/jpg');
      formData.append('image', blob);
    } else {
      formData.append('image', null);
    }
    saveCameraShotAction(formData);
  }
  const { WebcamHandler, trigger } = useCameraHandler(isCameraNeeded, onCapture);

  const { isTranslateEnabled, DisableModal } = useDisableOnTranslation();

  return (
    <div className={styles.QuestionMainWrapper}>
      <WebcamHandler />
      {qInfo && (
        <>
          <QuestionHeader />
          <InfoBanner
            text={bannerText}
            show={showBanner}
            CustomIcon={error ? Error : null}
            setStatus={async type => {
              await dispatch(setBannerStatus(type));
              dispatch(setBannerText(t('submissionSuccess')));
            }}
          />
          {!timerLoading && (
            <Timer
              qIndex={qInfo.questionIndex}
              qCount={qInfo.totalQuestion}
              totalTime={qInfo.questionTime}
              onNextClick={onNextClick}
              onSubmitClick={onSubmitClick}
              screenWidth={screenWidth}
              qStartDate={qInfo.startDate}
              qNow={qInfo.now}
            />
          )}

          {isTranslateEnabled ? (
            <DisableModal />
          ) : (
            <>
              {!timerLoading && qInfo?.questionTypeId === 1 && (
                <MultipleChoiceQuestion
                  qData={qInfo}
                  qDesc={qDescription}
                  headersHeight={headersHeight}
                  selected={[selectedIndex, setSelectedIndex]}
                  mcMobileThreshold={ASSESSMENT_FLOW_MOBILE_THRESHOLD}
                  screenWidth={screenWidth}
                />
              )}
              {!timerLoading && qInfo?.questionTypeId === 3 && (
                <FreeTextQuestion
                  qData={qInfo}
                  qDesc={qDescription}
                  headersHeight={headersHeight}
                  ftAnswer={[ftAnswer, setFtAnswer]}
                  ftMobileThreshold={ASSESSMENT_FLOW_MOBILE_THRESHOLD}
                  screenWidth={screenWidth}
                />
              )}
              {!timerLoading && qInfo?.questionTypeId === 2 && (
                <CodingQuestion
                  qData={qInfo}
                  qDesc={qDescription}
                  headersHeight={headersHeight}
                  codingAnswer={[codingAnswer, setCodingAnswer]}
                  codingLanguage={[codeLanguage, setCodeLanguage]}
                  codingMobileThreshold={ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}
                  screenWidth={screenWidth}
                  selectedIndex={[selectedLangIndex, setSelectedLangIndex]}
                  resetToInitialCode={resetToInitialCode}
                />
              )}
              {!timerLoading && qInfo?.questionTypeId === 4 && (
                <MultiCorrect
                  qData={qInfo}
                  qDesc={qDescription}
                  headersHeight={headersHeight}
                  selected={[selectedIndexes, setSelectedIndexes]}
                  mcMobileThreshold={ASSESSMENT_FLOW_MOBILE_THRESHOLD}
                  screenWidth={screenWidth}
                />
              )}
            </>
          )}
        </>
      )}

      {/* <BottomLogo logoPath={mockImage} /> */}
    </div>
  );
};

export default QuestionPage;
