import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ReactGA from 'react-ga4';

import { useTranslation, Trans } from 'react-i18next';

import { CookiesProvider } from 'react-cookie';
import styled from 'styled-components';
import LoginPage from './Auth/Pages/Login/LoginPage';
import AddAssessment from './Assessment/AddAssessment/Pages/addAssessment';
import route from './constant/route';
import {
  authSelector,
  settingsPageSuccessMessage,
  settingsPageSuccessShow,
  userSelector
} from './redux/auth/selectors';
import Question from './Assessment/Test/Pages/addAssessmentShowQuestion';
import CodingQuestionPage from './Assessment/Question/Pages/codingQuestionPage';
import MultiSelectQuestionPage from './Assessment/Question/Pages/multiSelectQuestionPage';
import MultiCorrectAnswerPage from './Assessment/Question/Pages/multiCorrectAnswerPage';
import FreeTextQuestionPage from './Assessment/Question/Pages/freeTextQuestionPage';
import InvitedUserActivatePage from './Auth/Pages/Signup/InvitedUserActivatePage';
import './style/globalStyle.css';
import AddPosition from './Assessment/CoensioAIFlow/Pages/index';
import AssessmentCustomizationPage from './Assessment/AddAssessment/Pages/AssessmentCustomizationPage';
import QuestionBasedAssessmentPage from './Assessment/AddAssessment/Pages/QuestionBasedAssessmentPage';
import CandidateResultPublicPage from './Candidate/Pages/CandidateResultPublicPage';
import { getDomainProtocol, getOnlyDomain } from './utils/helpers';
import { setLoading, setSettingsPageSuccessShow } from './redux/auth/slice';
import WelcomeCandidatePage from './Candidate/Pages/WelcomeCandidatePage';
import QuestionPage from './Assessment/Test/Pages/QuestionPage/QuestionPage';
import CandidateResultPage from './Assessment/Test/Pages/CandidateResultPage';
import AssessmentSessionInformation from './Assessment/Test/Pages/AssessmentSessionInformation';
import ApplicationPage from './Candidate/Pages/ApplicationPage';
import SqlQuestionPage from './Assessment/Question/Pages/sqlQuestionPage';
import SalesPage from './Auth/Pages/SalesPage/SalesPage';
import TryAssessmentPage from './Assessment/AddAssessment/Pages/TryAssessmentPage';
import CreateAssessmentPage from './Assessment/AddAssessment/Pages/CreateAssessmentPage';
import SettingsPage from './Auth/Pages/Settings/SettingsPage';
import {
  currentPlanRedux,
  paymentMethodModalShow,
  ShowPackageDetail
} from './redux/plan/selectors';
import { getCurrentPlanAction, getCurrentUsageAction } from './redux/plan/actions';
import { useInterval } from './utils/useInterval';
import { getCurrentUserDetailAction } from './redux/auth/actions';
import UnpaidContactSalesModal from './Auth/Modals/UnpaidContactSales/UnpaidContactSalesModal';
import ContactSalesSuccess from './Auth/Modals/UnpaidContactSales/ContactSalesSuccess';
import {
  setContactSalesModalShow,
  setPaymentMethodModalShow,
  setShowPackageDetail
} from './redux/plan/slice';
import PaymentMethod from './Auth/Modals/PaymentMethod';
import InfoBanner from './Common/Components/InfoBanner';
import NotFound from './Common/Pages/NotFound/NotFound';
import SignupPage from './Auth/Pages/Signup/SignupPage';
import ForgotPasswordPage from './Auth/Pages/ForgotPassword/ForgotPasswordPage';
import NewPasswordPage from './Auth/Pages/NewPassword/NewPasswordPage';
import UserInfoPage from './Auth/Pages/Signup/UserInfoPage';
import mixpanel, { EXCLUDED_COMPANY_LIST } from './utils/mixpanel';
import Loading from './Common/Components/Loading';
import Maintenance from './Common/Pages/UnderConstruction/Construction';
import CandidateDetailPage from './Candidate/Pages/CandidateDetail/CandidateDetailPage';
import PreviewAssessment from './Assessment/PreviewAssessment/Pages/PreviewAssessment';
import PackageDetailsModal from './Auth/Modals/PackageDetailsModal';
import AssessmentRecordPage from './Auth/Pages/AssessmentRecord';
import EditAssessmentDetail from './Auth/Pages/EditAssessmentDetail/EditAssessmentDetail';
import IsScreeningSettings from './Auth/Pages/IsScreeningSettings/IsScreeningSettings';
import CameraControl from './Auth/Pages/CameraControl/CameraControl';
import EditCandidateResult from './Auth/Pages/EditCandidateResult/EditCandidateResult';
import CompanyCandidateResult from './Auth/Pages/EditCandidateResult/CompanyCandidateResult';
import RegistrationCompletePage from './Auth/Pages/Signup/RegistrationCompletePage';
import ErrorBoundary from './Auth/Components/ErrorBoundary';
import RootAssessmentClone from './Auth/Pages/RootAssessmentClone/RootAssessmentClone';
import { useIp } from './hooks/useIp';
import PageContainer from './Common/Pages/PageContainer';
import { initGA } from './utils/analytics';

const AssessmentsPage = React.lazy(() =>
  import('./Assessment/CompanyAssessmentList/Pages/CompanyAssessmentsPage')
);
const AdminDashboardPage = React.lazy(() => import('./Auth/Pages/AdminDashboard'));
const ElasticRecordPage = React.lazy(() => import('./Auth/Pages/ElasticRecord/index'));
const CandidateEvaluationPage = React.lazy(() =>
  import('./Auth/Pages/CandidateEvaluation/CandidateEvaluationContent')
);
const ElasticMultiRecordPage = React.lazy(() =>
  import('./Auth/Pages/ElasticRecord/ElasticMultiRecordContent')
);
const AutoApplyAdminPage = React.lazy(() => import('./Auth/Pages/AutoApplyAdmin'));
const AutoApplyAdminControl = React.lazy(() => import('./Auth/Pages/AutoApplyAdminControl'));
const AssessmentAdditionalScreeningQuestions = React.lazy(() =>
  import(
    './Auth/Pages/AssessmentAdditionalScreeningQuestions/AssessmentAdditionalScreeningQuestions'
  )
);
const AssessmentDetail = React.lazy(() =>
  import('./Assessment/AssessmentDetail/Pages/AssessmentDetailPage')
);
const PositionWithouAssessmentAdminPage = React.lazy(() =>
  import('./Auth/Pages/PositionWithoutAssessment')
);
const SurveyPage = React.lazy(() => import('./Survey/Pages/SurveyPage'));
const Insights = React.lazy(() => import('./Insights/Pages'));
const CaseStudyPage = React.lazy(() => import('./Candidate/Pages/CaseStudy'));
const MailTrigger = React.lazy(() => import('./Auth/Pages/MailTrigger/MailTrigger'));
const MailingList = React.lazy(() => import('./Auth/Pages/MailingList/MailingList'));
const MailTemplateEdit = React.lazy(() =>
  import('./Auth/Pages/MailTemplateEditor/MailTemplateEditor')
);

function App() {
  useIp();
  initGA();

  const UNDER_MAINTENANCE_FLAG = false;

  const CURRENT_PLAN_REQUEST_INTERVAL = 600000; // 10min
  // const CURRENT_USER_REQUEST_INTERVAL = 600000  // 10min

  // Eğer link eskiyse, i.e. "coensio.coens.io" formatı, ya da direkt subdomain yoksa
  // subdomain'i app olarak ata
  function checkSubdomain() {
    if (window.location.host === '18.184.207.179') {
      return;
    }
    const arr = window.location.href.split('://');
    const subdom = arr[1].split(getOnlyDomain());
    if (subdom[0] !== 'app.') {
      window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}${subdom[1]}`;
    }
  }
  useEffect(() => {
    checkSubdomain();
  }, []);

  const dispatch = useDispatch();
  const currentPlan = useSelector(currentPlanRedux);
  const loginState = useSelector(authSelector);
  const user = useSelector(userSelector);

  // 2 endpoint için de token var mı yok mu check'i
  const isLoggedIn = () => {
    if (loginState.token && loginState.token2) {
      dispatch(setLoading(false));
      return true;
    }
    return false;
  };

  // public sayfalar - login olmadan da erişilebiliyor olmalı
  const reachablePages = [
    route.activate,
    route.refreshPassword,
    route.welcomeCandidate,
    route.candidateResult,
    route.register,
    route.forgotPassword,
    route.userActivate,
    route.companyUserActivate,
    route.assessmentPublic().split('/:id')[0],
    '/candidate-public',
    route.assessmentSessionInformation,
    route.assessmentSession,
    route.notFound,
    '/survey',
    '/case',
    '/registration-completed'
  ];

  const adminPages = [
    route.adminDashboard,
    route.multiSelectQuestion,
    route.freeTextQuestion,
    route.codingQuestion,
    route.sqlQuestion,
    route.multiCorrectAnsQuestion,
    route.salesScreen().split('/:index')[0],
    route.editCompanyCandidateResult,
    route.autoApplyAdminControl,
    route.editAssessmentDetails,
    route.editCandidateResult,
    route.positionWithoutAssessment,
    route.isScreeninAdminDashboard,
    route.cameraControlPanel,
    route.assessmentRecord,
    route.evaluationRecord,
    route.autoApplyAdmin,
    route.elasticRecord,
    route.assessmentAdditionalScreeningQuestions,
    route.mailTemplateEdit,
    route.mailTrigger,
    route.mailingTransactions,
    route.elasticMultiRecord,
    route.rootAssessmentClone
  ];

  const fetchUserInfo = async () => {
    const resp = await dispatch(getCurrentUserDetailAction());

    /* --------------- Mixpanel identify  --------------- */
    // After login or change company

    if (resp.meta.requestStatus === 'fulfilled') {
      const isExcluded = EXCLUDED_COMPANY_LIST?.includes(resp?.payload?.companyName);
      if (!isExcluded) {
        mixpanel.identify(resp.payload.userId);

        if (resp.payload.userGroupType !== 'Super Admin') {
          mixpanel.people.set({
            $name: resp.payload.userNameSurname,
            $email: resp.payload.email,
            'Company Name': resp.payload.companyName,
            'Admin Flag': resp.payload.isAdmin,
            'User Role': resp.payload.userGroupType
          });

          mixpanel.set_group('company_name', resp.payload.companyName);
          mixpanel
            .get_group('company_name', resp.payload.companyName)
            .set_once('$name', resp.payload.companyName);
        } else {
          mixpanel.people.set({
            $name: resp.payload.userNameSurname,
            $email: resp.payload.email,
            'Company Name': 'Coensio'
          });
          mixpanel.set_group('company_name', 'Coensio');
        }
      }
    }
    /* -------------------------------------------------- */
  };

  const [isUnpaidUser, setIsUnpaidUser] = useState(false);
  const [contactSalesSucess, setContactSalesSuccess] = useState(false);
  const showPackageModal = useSelector(ShowPackageDetail);

  useEffect(() => {
    // Giriş yapılıysa ya da yeni giriş yaptıysa
    if (isLoggedIn()) {
      // url /login'se ve giriş yapılıysa -> assessments'a yönlendir
      if (
        window.location.pathname.toLowerCase() === '/login' ||
        window.location.pathname.toLowerCase() === '/login/'
      ) {
        setTimeout(() => {
          const redirectLink = localStorage.getItem('redirect');
          if (redirectLink) {
            localStorage.removeItem('redirect');
            window.location.href = redirectLink;
          } else
            window.location.href = `${getDomainProtocol()}://app.${getOnlyDomain()}/assessments`;
        }, 100);
      }

      if (!user || user.version !== 3) {
        if (!loginState.loading && !window.location.pathname.toLowerCase().startsWith('/login')) {
          fetchUserInfo();
        }
      }
      // önceden giriş yapılıysa herhangi bir check yok, direkt var olan sayfaya devam
    }
    // Giriş yapılmadıysa -> public page mi değil mi kontrol et
    else {
      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });
      if (!isPublic && window.location.pathname !== '/login') {
        localStorage.setItem('redirect', window.location.href);
        window.location.pathname = '/login';
      }
    }
  }, [loginState]);

  // User değiştiğinde + sayfa yenilendiğinde getCurrentPlan'ı çek
  useEffect(() => {
    if (user) {
      const data = {
        CompanyId: user?.companyId
      };

      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });

      if (!isPublic) {
        dispatch(getCurrentPlanAction(data));
        dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
      }
    }
  }, [user]);

  // 10 dakikada bir current planı çek
  useInterval(() => {
    if (user) {
      const data = {
        CompanyId: user?.companyId
      };
      dispatch(getCurrentPlanAction(data));
    }
  }, CURRENT_PLAN_REQUEST_INTERVAL);

  // Unpaid check
  useEffect(() => {
    if (currentPlan) {
      let isPublic = false;
      reachablePages.forEach(e => {
        if (e === window.location.pathname) {
          isPublic = true;
        } else if (window.location.pathname.toLowerCase().startsWith(e.toLowerCase())) {
          isPublic = true;
        }
      });

      if (currentPlan?.payStatus === 20 && !isPublic) {
        setIsUnpaidUser(true);
      } else {
        setIsUnpaidUser(false);
      }
    }
  }, [currentPlan]);

  const paymentMethodShow = useSelector(paymentMethodModalShow);
  const seePlansShow = useSelector(ShowPackageDetail);
  const successText = useSelector(settingsPageSuccessMessage);
  const successShow = useSelector(settingsPageSuccessShow);

  const { t } = useTranslation(['banner']);

  return (
    <ErrorBoundary>
      <CookiesProvider>
        <UnpaidContactSalesModal
          freeTrialEnded={currentPlan?.name === 'Free Trial'}
          addOnCode={10}
          show={
            isUnpaidUser &&
            !contactSalesSucess &&
            !paymentMethodShow &&
            !seePlansShow &&
            user?.userGroupType !== 'Super Admin'
          }
          onHide={() => setIsUnpaidUser(false)}
          title={currentPlan?.name === 'Free Trial' ? t('thanksTry') : t('accSuspend')}
          description={
            currentPlan?.name === 'Free Trial' ? (
              t('trialEnded')
            ) : (
              <>
                {t('subNotRenewed')}
                <div>
                  {currentPlan?.isCustom ? t('contactToRenewSub') : t('changePayMethodToRenewSub')}
                </div>
              </>
            )
          }
          isCustom={currentPlan?.isCustom}
          setSuccess={setContactSalesSuccess}
        />
        {showPackageModal ? (
          <PackageDetailsModal
            show={showPackageModal}
            onHide={() => {
              dispatch(setShowPackageDetail(false));
            }}
          />
        ) : null}
        <PaymentMethod
          show={paymentMethodShow !== ''}
          onHide={() => dispatch(setPaymentMethodModalShow(''))}
        />
        <InfoBanner
          text={successText}
          show={successShow}
          setStatus={e => dispatch(setSettingsPageSuccessShow(e))}
        />
        <ContactSalesSuccess
          icon="tick"
          show={contactSalesSucess}
          onHide={() => {
            dispatch(setContactSalesModalShow(false));
          }}
          title={t('requestReceived')}
          description={t('expertContact')}
        />
        <Router basename="/">
          {UNDER_MAINTENANCE_FLAG ? (
            <Switch>
              <Route path="*">
                <Maintenance />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path={route.notFound}>
                <NotFound />
              </Route>
              <Route path={route.refreshPassword}>
                <NewPasswordPage />
              </Route>
              <Route path={route.activate}>
                <UserInfoPage />
              </Route>
              <Route path={route.welcomeCandidate}>
                <WelcomeCandidatePage />
              </Route>
              <Route path={route.candidateResult}>
                <CandidateResultPage />
              </Route>
              <Route path={route.register}>
                <SignupPage />
              </Route>
              <Route path="/registration-completed">
                <RegistrationCompletePage />
              </Route>
              <Route path={route.forgotPassword}>
                <ForgotPasswordPage />
              </Route>
              <Route path={[route.userActivate, route.companyUserActivate]}>
                <InvitedUserActivatePage />
              </Route>
              <Route path={route.assessmentPublic()}>
                <ApplicationPage />
              </Route>
              <Route path={route.assessments}>
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  <AssessmentsPage />
                </Suspense>
              </Route>
              <Route path="/assessment-detail/:id">
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  <AssessmentDetail />
                </Suspense>
              </Route>
              <Route path={route.addAssessment}>
                <AddAssessment />
              </Route>
              <Route path={route.question()}>
                <Question />
              </Route>
              <Route path={route.addPosition}>
                <AddPosition />
              </Route>
              <Route path={route.previewAssessment}>
                <PreviewAssessment />
              </Route>
              <Route path="/candidate-public">
                <CandidateResultPublicPage />
              </Route>
              <Route path="/candidate-detail/:id">
                <CandidateDetailPage />
              </Route>
              <Route path="/assessment-customization/:id">
                <AssessmentCustomizationPage />
              </Route>
              <Route path="/question-based-assessment/:id">
                <QuestionBasedAssessmentPage />
              </Route>
              <Route path="/try-assessment/:id">
                <TryAssessmentPage />
              </Route>
              <Route path="/create-assessment/:id/:index">
                <CreateAssessmentPage />
              </Route>
              <Route path={route.login}>
                <LoginPage />
              </Route>
              <Route path={route.settings()}>
                <SettingsPage />
              </Route>
              <Route path={route.assessmentSessionInformation}>
                <AssessmentSessionInformation />
              </Route>
              <Route path={route.assessmentSession}>
                <QuestionPage />
              </Route>
              <Route path={route.insights}>
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  <Insights />
                </Suspense>
              </Route>
              <Route path={route.survey()}>
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  <SurveyPage />
                </Suspense>
              </Route>
              <Route path={route.caseStudy()}>
                <Suspense
                  fallback={
                    <LoadingWrapper>
                      <Loading />
                    </LoadingWrapper>
                  }
                >
                  <CaseStudyPage />
                </Suspense>
              </Route>

              <Route path={adminPages}>
                {user && user.userGroupType === 'Super Admin' ? (
                  <Switch>
                    <Route path={route.codingQuestion}>
                      <CodingQuestionPage />
                    </Route>
                    <Route path={route.multiSelectQuestion}>
                      <MultiSelectQuestionPage />
                    </Route>
                    <Route path={route.freeTextQuestion}>
                      <FreeTextQuestionPage />
                    </Route>
                    <Route path={route.sqlQuestion}>
                      <SqlQuestionPage />
                    </Route>
                    <Route path={route.multiCorrectAnsQuestion}>
                      <MultiCorrectAnswerPage />
                    </Route>
                    <Route path={route.salesScreen()}>
                      <SalesPage />
                    </Route>
                    <Route path={route.adminDashboard}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <AdminDashboardPage />
                      </Suspense>
                    </Route>
                    <Route path={route.elasticRecord}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <ElasticRecordPage />
                      </Suspense>
                    </Route>
                    <Route path={route.evaluationRecord}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <CandidateEvaluationPage />
                      </Suspense>
                    </Route>
                    <Route path={route.elasticMultiRecord}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <ElasticMultiRecordPage />
                      </Suspense>
                    </Route>
                    <Route path={route.positionWithoutAssessment}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <PositionWithouAssessmentAdminPage />
                      </Suspense>
                    </Route>
                    <Route path={route.assessmentRecord}>
                      <AssessmentRecordPage />
                    </Route>
                    <Route path={route.autoApplyAdmin}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <AutoApplyAdminPage />
                      </Suspense>
                    </Route>
                    <Route path={route.autoApplyAdminControl}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <AutoApplyAdminControl />
                      </Suspense>
                    </Route>
                    <Route path={route.editAssessmentDetails}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <EditAssessmentDetail />
                      </Suspense>
                    </Route>
                    <Route path={route.isScreeninAdminDashboard}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <IsScreeningSettings />
                      </Suspense>
                    </Route>
                    <Route path={route.cameraControlPanel}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <CameraControl />
                      </Suspense>
                    </Route>
                    <Route path={route.editCandidateResult}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <EditCandidateResult />
                      </Suspense>
                    </Route>
                    <Route path={route.editCompanyCandidateResult}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <CompanyCandidateResult />
                      </Suspense>
                    </Route>
                    <Route path={route.assessmentAdditionalScreeningQuestions}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <AssessmentAdditionalScreeningQuestions />
                      </Suspense>
                    </Route>

                    <Route path={route.mailTemplateEdit}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <PageContainer>
                          <MailTemplateEdit />
                        </PageContainer>
                      </Suspense>
                    </Route>

                    <Route path={route.mailTrigger}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <PageContainer>
                          <MailTrigger />
                        </PageContainer>
                      </Suspense>
                    </Route>

                    <Route path={route.mailingTransactions}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <PageContainer>
                          <MailingList />
                        </PageContainer>
                      </Suspense>
                    </Route>

                    <Route path={route.rootAssessmentClone}>
                      <Suspense
                        fallback={
                          <LoadingWrapper>
                            <Loading />
                          </LoadingWrapper>
                        }
                      >
                        <RootAssessmentClone />
                      </Suspense>
                    </Route>
                  </Switch>
                ) : (
                  <span>You are not authorized to see this page!!!</span>
                )}
              </Route>

              <Route
                exact
                path="/"
                render={() =>
                  loginState.isLogged ? (
                    <Redirect to={route.assessments} />
                  ) : (
                    <Redirect to={route.login} />
                  )
                }
              />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          )}
        </Router>
      </CookiesProvider>
    </ErrorBoundary>
  );
}

export default App;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
