import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useTranslation, Trans } from 'react-i18next';

import EmailText from '../../../Common/Components/EmailText';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import styles from './LoginComponent.module.css';
import PasswordInput from '../../../Common/Components/PasswordInput';
import CustomButton from '../../../Common/Components/CustomButton';
import { loginVol2Action } from '../../../redux/auth/actions';
import route from '../../../constant/route';

const LoginComponent = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['login']);

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorDisable, setErrorDisable] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const loginButtonClick = async () => {
    setButtonLoading(true);
    if (!executeRecaptcha) {
      console.error('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('login');

    if (token) {
      const rawData = {
        email,
        password,
        reCaptchaToken: token
      };
      const resp = await dispatch(loginVol2Action(rawData));

      if (resp.meta.requestStatus !== 'fulfilled') {
        setButtonLoading(false);
      }
    } else {
      alert('Captcha token could not be generated');
      setButtonLoading(false);
    }
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.loginCard}>
            <CoensioLogo className={styles.logo} />
            <label className={styles.cardTitle}>{t('welcome')}</label>
            <div className={styles.textInputWrapper}>
              <EmailText
                value={email}
                setValue={setEmail}
                errorDisable
                setErrorDisable={setErrorDisable}
                placeholder={t('email')}
                showInvisibleError
                trimWhiteSpaces
              />
            </div>
            <div className={styles.textInputWrapper}>
              <PasswordInput
                value={password}
                placeholder={t('password')}
                onChange={e => setPassword(e.target.value)}
                setParentErrorState={setErrorDisable}
                showInvisibleError
              />
            </div>

            <div className={styles.forgotPasswordWrapper}>
              <Link to={route.forgotPassword} style={{ cursor: 'pointer' }}>
                {t('forgotPassword')}
              </Link>
            </div>
            <div className={styles.buttonWrapper}>
              <CustomButton
                isDisabled={!email || !password || errorDisable}
                customStyle={{ width: '100%' }}
                textField={t('login')}
                buttonOnClick={loginButtonClick}
                loading={buttonLoading}
                enterEnabled
              />
            </div>
          </div>
          {/* <div style={{ display: 'flex' }}>
            <div className={styles.noAccount}>{t('noAcc')}</div>
            <Link to={route.register}>
              <div className={styles.signup}>{t('signup')}</div>
            </Link>
          </div> */}
          <span style={{ display: 'flex', gap: '4px' }}>
            <Trans i18nKey="noAccSignup" ns="login">
              0<div className={styles.noAccount}>1</div>
              <Link to={route.register} className={styles.signup}>
                2
              </Link>
            </Trans>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
