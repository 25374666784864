import React from 'react';
import Editor from '@monaco-editor/react';
import styles from './QuestionReportBody.module.css';

function QuestionDetailMultipleChoice({
  qDesc,
  mcOptions,
  mcCorrectAnswer,
  mcSelectedAnswer,
  imageOpt
}) {
  let opt = [];
  try {
    opt = JSON.parse(mcOptions);
  } catch (error) {
    console.error(error, 'error on parsing multiple choice options');
  }

  return (
    <div className={styles.BodyWrapper}>
      <div className={styles.MultipleChoiceWrapper}>
        <div
          className={styles.MultipleChoiceQuestionText}
          dangerouslySetInnerHTML={{ __html: qDesc }}
        />
        {opt.map((e, index) => (
          <div className={styles.OptionWrapper}>
            <div
              className={`${styles.OptionRadioCheckbox} ${
                index === mcCorrectAnswer && styles.CorrectOption
              } `}
            >
              {index === mcSelectedAnswer && (
                <div
                  className={`${styles.SelectedOption} ${
                    index === mcCorrectAnswer && styles.CorrectSelectedOption
                  } `}
                />
              )}
            </div>
            {imageOpt ? (
              <div
                className={`${styles.OptionText} ${
                  index === mcSelectedAnswer && styles.SelectedOptionText
                }`}
                dangerouslySetInnerHTML={{ __html: e }}
              />
            ) : (
              <div
                className={`${styles.OptionText} ${
                  index === mcSelectedAnswer && styles.SelectedOptionText
                }`}
              >
                {e}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function QuestionDetailMultiCorrectAnswer({
  qDesc,
  mcOptions,
  questionAnswerOptions,
  candidateAnswerOptions,
  imageOpt
}) {
  return (
    <div className={styles.BodyWrapper}>
      <div className={styles.MultipleChoiceWrapper}>
        <div
          className={styles.MultipleChoiceQuestionText}
          dangerouslySetInnerHTML={{ __html: qDesc }}
        />
        {JSON.parse(mcOptions).map((e, index) => (
          <div className={styles.OptionWrapper} key={index}>
            <div
              className={`${styles.OptionCheckbox} ${
                questionAnswerOptions.includes(index) && styles.CorrectOption
              }`}
            >
              {candidateAnswerOptions.includes(index) && (
                <div
                  className={`${styles.SelectedOptionCheckbox} ${
                    questionAnswerOptions.includes(index) && styles.CorrectSelectedOption
                  }`}
                />
              )}
            </div>
            {imageOpt ? (
              <div
                className={`${styles.OptionText} ${
                  candidateAnswerOptions.includes(index) && styles.SelectedOptionText
                }`}
                dangerouslySetInnerHTML={{ __html: e }}
              />
            ) : (
              <div
                className={`${styles.OptionText} ${
                  candidateAnswerOptions.includes(index) && styles.SelectedOptionText
                }`}
                dangerouslySetInnerHTML={{ __html: e }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function QuestionDetailFreeText({ qDesc, ftCandidateAnswer }) {
  return (
    <div className={styles.BodyWrapper}>
      <div className={styles.FreeTextWrapper}>
        <div className={styles.FreeTextQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
        {ftCandidateAnswer && (
          <div className={styles.FreeTextAnswerWrapper}>{ftCandidateAnswer}</div>
        )}
      </div>
    </div>
  );
}

function QuestionDetailCoding({ qDesc, cdCandidateCodeLang, cdCandidateAnswer }) {
  let lang = '';

  switch (cdCandidateCodeLang) {
    case 'C - Coding':
      lang = 'c';
      break;
    case 'C++ - Coding':
      lang = 'cpp';
      break;
    case 'C# - Coding':
      lang = 'csharp';
      break;
    case 'Java - Coding':
      lang = 'java';
      break;
    case 'Javascript - Coding':
      lang = 'javascript';
      break;
    case 'Kotlin - Coding':
      lang = 'kotlin';
      break;
    case 'Python3 - Coding':
      lang = 'python';
      break;
    case 'Swift - Coding':
      lang = 'swift';
      break;
    case 'Dart - Coding':
      lang = 'dart';
      break;

    default:
      break;
  }

  const SINGLE_LINE_HEIGHT = 18;
  const BUFFER_LINE_COUNT = 0;
  const lineCount = (cdCandidateAnswer?.split('\n')?.length || 0) + BUFFER_LINE_COUNT;

  return (
    <div className={styles.CodingBodyWrapper}>
      <div className={styles.CodingWrapper}>
        <div className={styles.CodingQuestionText} dangerouslySetInnerHTML={{ __html: qDesc }} />
      </div>
      <div className={styles.CodingEditorWrapper}>
        <Editor
          defaultLanguage={lang}
          className={styles.MonacoEditor}
          defaultValue={
            cdCandidateAnswer.startsWith('"/') ? JSON.parse(cdCandidateAnswer) : cdCandidateAnswer
          }
          theme="vs-dark"
          options={{
            wordWrap: true,
            readOnly: true,
            minimap: { enabled: false },
            stickyTabStops: false
          }}
          height={`max(${lineCount * SINGLE_LINE_HEIGHT}px, 100%)`}
        />
      </div>
    </div>
  );
}

function QuestionReportBody({
  type,
  qBody,
  mcCorrectAnswer,
  mcSelectedAnswer,
  mcOptions,
  ftCandidateAnswer,
  cdCandidateAnswer,
  candidateAnswerOptions,
  questionAnswerOptions,
  cdCandidateCodeLang,
  imageOpt
}) {
  let qDesc = [];
  try {
    qDesc = JSON.parse(qBody);
    // qDesc = qDesc.replaceAll("https://api.coens.io", process.env.REACT_APP_IMAGE_API);
    qDesc = qDesc.replaceAll('https://api.coens.io/', '');
    qDesc = qDesc.replaceAll('https://file.coens.io/', '');
    qDesc = qDesc.replaceAll('<img src="', `<img src="${process.env.REACT_APP_IMAGE_API}/`);
    qDesc = qDesc.replaceAll('<source src="', `<source src="${process.env.REACT_APP_IMAGE_API}/`);
    qDesc = qDesc.replaceAll('font-size: 18px;', 'font-size: unset;');
  } catch (error) {
    console.error(error, 'error on parsing question body');
  }

  switch (type) {
    case 1:
      return QuestionDetailMultipleChoice({
        qDesc,
        mcOptions,
        mcCorrectAnswer,
        mcSelectedAnswer,
        imageOpt
      });
    case 2:
      return QuestionDetailCoding({ qDesc, cdCandidateCodeLang, cdCandidateAnswer });
    case 3:
      return QuestionDetailFreeText({ qDesc, ftCandidateAnswer });
    case 4:
      return QuestionDetailMultiCorrectAnswer({
        qDesc,
        mcOptions,
        questionAnswerOptions,
        candidateAnswerOptions,
        imageOpt
      });

    default:
      return QuestionDetailFreeText({ qDesc, ftCandidateAnswer });
  }
}
export default QuestionReportBody;
