import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const SettingsTab = ({ title, redirect, ...props }) => {
  const history = useHistory();
  const { tab } = useParams();

  const isActiveTab = tab === redirect.toLowerCase().replace(' ', '');

  return (
    <div className="FlexRow verticallyCenter" style={{ marginLeft: '35px' }}>
      <span
        className={`fw-400 font-20 pointer noSelect c-darkGray ${isActiveTab ? 'c-coensio' : ''}`}
        style={{ whiteSpace: 'nowrap', ...props.textStyle }}
        onClick={() => history.replace(`/settings/${redirect.toLowerCase().replace(' ', '')}`)}
      >
        {title}
      </span>
    </div>
  );
};

export default SettingsTab;
