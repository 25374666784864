import {
  getElasticUserInfoService,
  updateUserElasticInfoService,
  updateMultiUserElasticInfoService,
  getUserAutoApplyListService,
  changeHasAssessmentService,
  getAllCompanyAutoApplyListService,
  updateAutoApplyStatusService,
  getCustomFunnelListService,
  addCustomFunnelService,
  renameCustomFunnelService,
  deleteCustomFunnelService,
  updateCustomFunnelGroupService,
  getPositionDetailsService,
  modifyPositionDetailsService,
  candidateEvaluationService,
  getCompanyAssessmentListService,
  getCompanyListService,
  changeAssessmentNeedsService,
  getDefaultRequirementsService,
  updateDefaultRequirementsService,
  getContentService,
  updateContentService,
  getAdditionalScreeningQuestionsService,
  createAdditionalScreeningQuestionsService,
  deleteAdditionalScreeningQuestionService,
  getBaseTemplatesService,
  getAllCompanyLogoService,
  saveNotificationTemplateService,
  getNotificationTemplatesService,
  saveNotificationScheduleService,
  getAllNotificationsService,
  getNotificationScheduleDetailService,
  getRootAssessmentCloneService,
  copyRootAssessmentService
} from './service';

export const getElasticUserInfoAction = async data => {
  try {
    return await getElasticUserInfoService(data);
  } catch (error) {
    return null;
  }
};

export const updateUserElasticInfoAction = async data => {
  try {
    return await updateUserElasticInfoService(data);
  } catch (error) {
    return null;
  }
};
export const updateMultiUserElasticInfoAction = async data => {
  try {
    return await updateMultiUserElasticInfoService(data);
  } catch (error) {
    return null;
  }
};

export const getUserAutoApplyListAction = async data => {
  try {
    return await getUserAutoApplyListService(data);
  } catch (error) {
    return null;
  }
};

export const changeHasAssessmentAction = async data => {
  try {
    return await changeHasAssessmentService(data);
  } catch (error) {
    return null;
  }
};

export const getCompaniesAutoApplyListAction = async () => {
  try {
    return await getAllCompanyAutoApplyListService();
  } catch (error) {
    return null;
  }
};

export const getCustomFunnelListAction = async (data, controller) => {
  try {
    return await getCustomFunnelListService(data, controller);
  } catch (error) {
    return null;
  }
};

export const addCustomFunnelAction = async data => {
  try {
    return await addCustomFunnelService(data);
  } catch (error) {
    return error;
  }
};

export const updateAutoApplyStatusAction = async data => {
  try {
    return await updateAutoApplyStatusService(data);
  } catch (error) {
    return error;
  }
};

export const renameCustomFunnelAction = async data => {
  try {
    return await renameCustomFunnelService(data);
  } catch (error) {
    return error;
  }
};

export const deleteCustomFunnelAction = async data => {
  try {
    return await deleteCustomFunnelService(data);
  } catch (error) {
    return error;
  }
};

export const updateCustomFunnelGroupAction = async data => {
  try {
    return await updateCustomFunnelGroupService(data);
  } catch (error) {
    return error;
  }
};

export const getPositionDetailsAction = async data => {
  try {
    return await getPositionDetailsService(data);
  } catch (error) {
    return error;
  }
};

export const modifyPositionDetailsAction = async data => {
  try {
    return await modifyPositionDetailsService(data);
  } catch (error) {
    return error;
  }
};

export const evaluateCandidatesAction = async data => {
  try {
    return await candidateEvaluationService(data);
  } catch (error) {
    return error;
  }
};

export const getCompanyListAction = async data => {
  try {
    return await getCompanyListService(data);
  } catch (error) {
    return error;
  }
};

export const getCompanyAssessmentListAction = async data => {
  try {
    return await getCompanyAssessmentListService(data);
  } catch (error) {
    return error;
  }
};

export const changeAssessmentNeedsAction = async data => {
  try {
    return await changeAssessmentNeedsService(data);
  } catch (error) {
    return error;
  }
};

export const getDefaultRequirementsAction = async data => {
  try {
    return await getDefaultRequirementsService(data);
  } catch (error) {
    return error;
  }
};

export const updateDefaultRequirementsAction = async data => {
  try {
    return await updateDefaultRequirementsService(data);
  } catch (error) {
    return error;
  }
};

export const getContentAction = async data => {
  try {
    return await getContentService(data);
  } catch (error) {
    return error;
  }
};

export const updateContentAction = async data => {
  try {
    return await updateContentService(data);
  } catch (error) {
    return error;
  }
};

export const getAdditionalScreeningQuestionsAction = async data => {
  try {
    return await getAdditionalScreeningQuestionsService(data);
  } catch (error) {
    return error;
  }
};

export const createAdditionalScreeningQuestionsAction = async data => {
  try {
    return await createAdditionalScreeningQuestionsService(data);
  } catch (error) {
    return error;
  }
};

export const deleteAdditionalScreeningQuestionAction = async data => {
  try {
    return await deleteAdditionalScreeningQuestionService(data);
  } catch (error) {
    return error;
  }
};

export const getBaseTemplatesAction = async data => {
  try {
    return await getBaseTemplatesService(data);
  } catch (error) {
    return error;
  }
};

export const getRootAssessmentCloneAction = async data => {
  try {
    return await getRootAssessmentCloneService(data);
  } catch (error) {
    return error;
  }
};

export const getAllCompanyLogoAction = async () => {
  try {
    return await getAllCompanyLogoService();
  } catch (error) {
    return error;
  }
};

export const saveNotificationTemplateAction = async data => {
  try {
    return await saveNotificationTemplateService(data);
  } catch (error) {
    return error;
  }
};

export const getNotificationTemplatesAction = async data => {
  try {
    return await getNotificationTemplatesService(data);
  } catch (error) {
    return error;
  }
};

export const saveNotificationScheduleAction = async data => {
  try {
    return await saveNotificationScheduleService(data);
  } catch (error) {
    return error;
  }
};

export const getAllNotificationsAction = async data => {
  try {
    return await getAllNotificationsService(data);
  } catch (error) {
    return error;
  }
};

export const getNotificationScheduleDetailAction = async data => {
  try {
    return await getNotificationScheduleDetailService(data);
  } catch (error) {
    return error;
  }
};

export const copyRootAssessmentAction = async data => {
  try {
    return await copyRootAssessmentService(data);
  } catch (error) {
    return error;
  }
};
