import client from '../../constant/client';
import API from '../../constant/apiVol2';

export const getElasticUserInfoService = data =>
  new Promise((resolve, reject) => {
    client.post(API.getUserElasticInfo, data).then(resolve).catch(reject);
  });

export const updateUserElasticInfoService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateUserElasticInfo, data).then(resolve).catch(reject);
  });

export const updateMultiUserElasticInfoService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateMultiUserElasticInfo, data).then(resolve).catch(reject);
  });

export const getUserAutoApplyListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getUserAutoApplyList, { params: data }).then(resolve).catch(reject);
  });

export const changeHasAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.changeHasAssessment, data).then(resolve).catch(reject);
  });

export const getAllCompanyAutoApplyListService = () =>
  new Promise((resolve, reject) => {
    client.get(API.getAllCompanyAutoApplyList).then(resolve).catch(reject);
  });

export const updateAutoApplyStatusService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateAutoApplyStatus, data).then(resolve).catch(reject);
  });

export const getCustomFunnelListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCustomFunnelList, { params: data }).then(resolve).catch(reject);
  });

export const addCustomFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.addCustomFunnel, data).then(resolve).catch(reject);
  });

export const renameCustomFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.renameCustomFunnel, data).then(resolve).catch(reject);
  });

export const deleteCustomFunnelService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteCustomFunnel, data).then(resolve).catch(reject);
  });

export const updateCustomFunnelGroupService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateCustomFunnelGroup, data).then(resolve).catch(reject);
  });

export const getPositionDetailsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getPositionDetails, { params: data }).then(resolve).catch(reject);
  });

export const modifyPositionDetailsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.modifyPositionDetails, data).then(resolve).catch(reject);
  });

export const candidateEvaluationService = data =>
  new Promise((resolve, reject) => {
    client.post(API.evaluateCandidates, data).then(resolve).catch(reject);
  });

export const getCompanyListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyList, { params: data }).then(resolve).catch(reject);
  });

export const getCompanyAssessmentListService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCompanyAssessmentList, { params: data }).then(resolve).catch(reject);
  });

export const changeAssessmentNeedsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.changeAssessmentNeeds, data).then(resolve).catch(reject);
  });

export const getDefaultRequirementsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getDefaultRequirements, { params: data }).then(resolve).catch(reject);
  });

export const updateDefaultRequirementsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateDefaultRequirements, data).then(resolve).catch(reject);
  });

export const getContentService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getContent, { params: data }).then(resolve).catch(reject);
  });

export const updateContentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.updateContent, data).then(resolve).catch(reject);
  });

export const getAdditionalScreeningQuestionsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAdditionalScreeningQuestions, { params: data }).then(resolve).catch(reject);
  });

export const createAdditionalScreeningQuestionsService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createAdditionalScreeningQuestions, data).then(resolve).catch(reject);
  });

export const deleteAdditionalScreeningQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.deleteAdditionalScreeningQuestion, data).then(resolve).catch(reject);
  });

export const getBaseTemplatesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getBaseTemplates, { params: data }).then(resolve).catch(reject);
  });

export const getAllCompanyLogoService = () =>
  new Promise((resolve, reject) => {
    client.get(API.getAllCompanyLogo).then(resolve).catch(reject);
  });

export const saveNotificationTemplateService = data =>
  new Promise((resolve, reject) => {
    client.post(API.saveNotificationTemplate, data).then(resolve).catch(reject);
  });

export const getNotificationTemplatesService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getNotificationTemplates, { params: data }).then(resolve).catch(reject);
  });

export const saveNotificationScheduleService = data =>
  new Promise((resolve, reject) => {
    client.post(API.saveNotificationSchedule, data).then(resolve).catch(reject);
  });

export const getAllNotificationsService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getAllNotifications, { params: data }).then(resolve).catch(reject);
  });

export const getNotificationScheduleDetailService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getNotificationScheduleDetail, { params: data }).then(resolve).catch(reject);
  });

export const getRootAssessmentCloneService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getRootAssessmentClone, { params: data }).then(resolve).catch(reject);
  });

export const copyRootAssessmentService = data =>
  new Promise((resolve, reject) => {
    client.post(API.copyRootAssessment, data).then(resolve).catch(reject);
  });
