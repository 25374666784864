import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  postRunCodeService,
  getCreateQuestionDropdownService,
  getCreateQuestionSubcategoryDropdownService,
  getCreateQuestionAssessmentLabelDropdownService,
  createMultipleChoiceQuestionService2,
  createMultipleCorrectQuestionService2,
  createFreeTextQuestionService2,
  createCodingQuestionService2,
  postSqlRunCodeService,
  createSqlQuestionService,
  analyzeQuestionService
} from './serviceVol2';

export const postRunCodeAction = createAsyncThunk(
  'addQuestion/postRunCode',
  async (data, thunkAPI) => {
    try {
      const response = await postRunCodeService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCreateQuestionDropdownAction = createAsyncThunk(
  'addQuestion/getDropdowns',
  async (data, thunkAPI) => {
    try {
      const response = await getCreateQuestionDropdownService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCreateQuestionSubcategoryDropdownAction = createAsyncThunk(
  'addQuestion/getSubcategoryDropdown',
  async (data, thunkAPI) => {
    try {
      const response = await getCreateQuestionSubcategoryDropdownService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCreateQuestionAssessmentLabelDropdownAction = createAsyncThunk(
  'addQuestion/getAssessmentLabelDropdown',
  async (data, thunkAPI) => {
    try {
      const response = await getCreateQuestionAssessmentLabelDropdownService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMultipleChoiceQuestionAction2 = createAsyncThunk(
  'addQuestion/createMultipleChoiceQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await createMultipleChoiceQuestionService2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createMultipleCorrectQuestionAction2 = createAsyncThunk(
  'addQuestion/createMultipleCorrectQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await createMultipleCorrectQuestionService2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createFreeTextQuestionAction2 = createAsyncThunk(
  'addQuestion/createFreeTextQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await createFreeTextQuestionService2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const createCodingQuestionAction2 = createAsyncThunk(
  'addQuestion/createCodingQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await createCodingQuestionService2(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const postSqlRunCodeAction = createAsyncThunk(
  'addQuestion/postSqlRunCode',
  async (data, thunkAPI) => {
    try {
      const response = await postSqlRunCodeService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSqlQuestionAction = createAsyncThunk(
  'addQuestion/createSqlQuestion',
  async (data, thunkAPI) => {
    try {
      const response = await createSqlQuestionService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const analyzeQuestionAction = async data => {
  try {
    return await analyzeQuestionService(data);
  }
  catch (error) {
    return error;
  }
}
