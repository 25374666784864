import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Oval, Preloader } from 'react-preloader-icon';
import coding from '../../../images/AddAssessment/AssessmentCustomization/coding_assessment.svg';
import freetext from '../../../images/AddAssessment/AssessmentCustomization/freetext_assessment.svg';
import multiselect from '../../../images/AddAssessment/AssessmentCustomization/multiple_assessment.svg';
import multiplecorrect from '../../../images/AddAssessment/AssessmentCustomization/multiplecorrect_assessment.svg';
import clock from '../Images/clockDuration.svg';
import outSource from '../Images/outSource.svg';
import styles from '../Styles/FilteredQuestion.module.css';
import questionDelete from '../Images/deleteMinus.svg';
import questionPlus from '../../../images/AddAssessment/AssessmentCustomization/question_plus.svg';
import rightArrow from '../Images/right-arrow.svg';
import {
  customAssessmentId,
  rootAssessmentQuestionList
} from '../../../redux/assessmentsVol2/selectors';
import { addQuestionBasedQuestionAction } from '../../../redux/assessmentsVol2/actions';
import {
  setQuestionBeingAdded,
  setRemoveQuestionShow,
  setQuestionBeingRemoved
} from '../../../redux/assessmentsVol2/slice';
import Tooltip from '../../../Common/Components/Tooltip';
import { calculateMin, renderDescription } from '../../../utils/helpers';
import { ReactComponent as WorldIcon } from '../../../images/AddAssessment/AssessmentCustomization/world.svg';
import { CodingQuestion, FreeTextQuestions, MultipleChoiceQuestion, MultipleCorrectAnswer } from './AssessmentQuestion';
import TextOverflow from '../../../Common/Components/TextOverflow';

export default function FilteredQuestion({
  questionTypeId,
  language,
  skills,
  questionDuration,
  title,
  isIncluded,
  item,
  difficulty = 'Easy',
  setScrollPos = () => {},
  disableScrollTemp = () => {},
  isScrollAllowed = true,
  index,
  search,
  allowedMaxWidth,
  setEditDurationQuestionData
}) {
  const dispatch = useDispatch();
  const assID = useSelector(customAssessmentId);
  const rootQuestions = useSelector(rootAssessmentQuestionList);
  const [plusButtonLoading, setPlusButtonLoading] = useState(false);
  const [selectedLangs, setSelectedLangs] = useState();
  const [questionDescShown, setQuestionDescShown] = useState(false);
  const [qDescription, setqDescription] = useState();
  const [showEditButton, setShowEditButton] = useState(false);

  const editDuration = () => {
    if (questionTypeId === 2) {
      setShowEditButton(false);
    }
  };

  let questionBody = '';

  const goToQuestionDetail = async () => {
    window.open(`/question/${item?.questionId}`, '_blank').focus();
  };

  async function addQuestion() {
    const data = {
      rootAssessmentId: assID,
      questionId: item.questionId,
      category: item.category,
      languageList: item.codingLanguages,
      isCustom: item.isCustom
    };

    if (item.questionTypeId !== 2 || (item.codingLanguages && item.codingLanguages[0] === 'SQL')) {
      dispatch(addQuestionBasedQuestionAction(data));
    } else if (item.questionTypeId === 2) {
      const codingData = {
        type: 'Coding',
        rootAssessmentId: assID,
        questionId: item.questionId,
        category: item.category,
        languageList: item.codingLanguages
      };
      dispatch(setQuestionBeingAdded(codingData));
      disableScrollTemp(true);
      setPlusButtonLoading(false);
    }
  }

  const handleQuestionAddOrDelete = async () => {
    if (isScrollAllowed) {
      if (isIncluded) {
        const data = {
          rootAssessmentId: assID,
          questionId: item.questionId
        };
        dispatch(setRemoveQuestionShow(true));
        dispatch(setQuestionBeingRemoved(data));
      } else {
        setPlusButtonLoading(true);
        disableScrollTemp(false);
        addQuestion();
        setScrollPos();
      }
    }
  };

  const displayQuestion = type => {
    switch (type) {
      case 1:
        return (
          <MultipleChoiceQuestion
            mcOptions={item.options}
            qDesc={qDescription}
            isDetailedShown={false}
            imageOption={item.imageOption}
          />
        );
      case 2:
        return (
          <CodingQuestion mcOptions={item.options} qDesc={qDescription} isDetailedShown={false} />
        );
      case 3:
        return (
          <FreeTextQuestions
            mcOptions={item.options}
            qDesc={qDescription}
            isDetailedShown={false}
          />
        );
      case 4:
        return (
          <MultipleCorrectAnswer
            mcOptions={item.options}
            qDesc={qDescription}
            isDetailedShown={false}
            imageOption={item.imageOption}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isScrollAllowed) {
      setPlusButtonLoading(false);
    }
  }, [isScrollAllowed]);

  useEffect(() => {
    const a = rootQuestions?.filter(q => q?.questionId === item?.questionId)[0]?.label;
    setSelectedLangs(a);
  }, [rootQuestions, item]);

  useEffect(() => {
    setQuestionDescShown(false);
    try {
      questionBody = JSON.parse(item.description);
      questionBody = questionBody.replaceAll('https://api.coens.io/', '');
      questionBody = questionBody.replaceAll('https://file.coens.io/', '');
      questionBody = questionBody.replaceAll(
        '<img src="',
        `<img src="${process.env.REACT_APP_IMAGE_API}/`
      );
      questionBody = questionBody.replaceAll(
        '<source src="',
        `<source src="${process.env.REACT_APP_IMAGE_API}/`
      );
      setqDescription(questionBody);
    } catch (error) {
      console.error(error, 'error on parsing question desc');
    }
  }, [item]);

  useEffect(() => {
    if (questionDescShown) {
      const element = document.getElementById('filtered-questions');
      const scrolledQuestion = document.getElementById(`to-scrolled-${index}`);
      const secondRow = document.getElementById('question-based-middle-column-wrapper');
      if (element) {
        element.scrollBy({
          top:
            scrolledQuestion.getBoundingClientRect().top -
            secondRow.getBoundingClientRect().bottom -
            30,
          behavior: 'smooth'
        });
      }
    }
  }, [questionDescShown]);

  useEffect(() => {
    return () => {
      setQuestionDescShown(false);
    };
  }, [search]);

  return (
    <div
      style={{ maxWidth: allowedMaxWidth }}
      className={styles.wrapper}
      id={`to-scrolled-${index}`}
    >
      <div className={styles.titleWrapper}>
        <TextOverflow text={title} />
        {language !== 'EN' && (
          <div className={styles.languageLabel}>
            <WorldIcon className={styles.worldIcon} />
            <div className={styles.questionLanguage}>{`${language}`}</div>
          </div>
        )}
        <div className={styles.questionDifficulty}>{difficulty}</div>
        <div className={styles.iconWrapper}>
          <span className={styles.imgWrapper1} style={{ marginRight: '10px', cursor: 'pointer' }}>
            <img alt="" src={outSource} onClick={goToQuestionDetail} />
          </span>
          <span className={styles.imgWrapper2}>
            {!plusButtonLoading ? (
              <img
                style={{ cursor: 'pointer', width: 24, height: 24 }}
                alt=""
                onClick={handleQuestionAddOrDelete}
                src={isIncluded ? questionDelete : questionPlus}
              />
            ) : (
              <Preloader
                use={Oval}
                size={24}
                strokeWidth={17}
                strokeColor="#7458D3"
                duration={1200}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  marginBottom: '1px'
                }}
              />
            )}
          </span>
        </div>
      </div>

      <div
        id="q-desc-container-hm"
        className={styles.questionDescContainer}
        isDescriptionVisible={JSON.stringify(questionDescShown)}
        onClick={() => {
          setQuestionDescShown(!questionDescShown);
        }}
      >
        {/* 10 - Purchased
          20 - Free
          30 - Premium   */}
        <div
          className={styles.questionDescText}
          isDescriptionVisible={JSON.stringify(questionDescShown)}
        >
          <div
            className={styles.descriptionWhole}
            isDescriptionVisible={JSON.stringify(questionDescShown)}
          >
            {displayQuestion(item.questionTypeId)}
          </div>
          <div className={styles.descHeader} style={{ gap: 10 }}>
            <div
              className={styles.descriptionShort}
              style={{ maxWidth: !questionDescShown && '95%' }}
              isDescriptionVisible={JSON.stringify(questionDescShown)}
            >
              <div
                style={
                  !questionDescShown
                    ? { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }
                    : null
                }
              >
                {renderDescription(qDescription)}
              </div>
            </div>
            <img
              className={styles.collapseQDescButton}
              alt=""
              src={rightArrow}
              isDescriptionVisible={JSON.stringify(questionDescShown)}
            />
          </div>
        </div>
      </div>
      <div className={styles.rowWrapper}>
        <span style={{ marginRight: '4px' }}>
          <img
            alt=""
            width={33}
            height={24}
            src={
              questionTypeId === 2
                ? coding
                : questionTypeId === 3
                ? freetext
                : questionTypeId === 4
                ? multiplecorrect
                : multiselect
            }
          />
        </span>
        {item.questionTypeId !== 2 && <div className={styles.skillWrapper}>{item.category}</div>}
        {item?.questionTypeId === 2 && skills && skills[0] === 'SQL' && (
          <div className={styles.skillWrapper}>SQL</div>
        )}

        {!item?.isIncluded &&
          item?.questionTypeId === 2 &&
          skills &&
          skills[0] !== 'SQL' &&
          item?.codingLanguages?.length !== 1 && (
            <div className={styles.skillWrapper}>General Coding</div>
          )}

        {item.isIncluded &&
          item?.questionTypeId === 2 &&
          item?.codingLanguages?.length !== 1 &&
          selectedLangs
            ?.slice(0, 3)
            ?.map(lang => <div className={styles.skillWrapper}>{lang}</div>)}

        {item?.questionTypeId === 2 &&
          item?.codingLanguages?.length === 1 &&
          item?.codingLanguages[0] !== 'SQL' && (
            <div className={styles.skillWrapper}>{item.codingLanguages[0]}</div>
          )}
        {item.isIncluded && selectedLangs?.length >= 4 && item?.questionTypeId === 2 && (
          <Tooltip
            direction="right"
            theme="light"
            offset={10}
            content={
              <ul className={styles.skillList}>
                {selectedLangs?.slice(3, selectedLangs?.length).map(x => (
                  <li className={styles.skillElement}>
                    <span>{x}</span>
                  </li>
                ))}
              </ul>
            }
          >
            <div className={`${styles.skillWrapper} ${styles.more}`}>{`+${
              selectedLangs?.length - 3
            }`}</div>
          </Tooltip>
        )}

        {questionTypeId !== 2 && (
          <div
            className={`FlexRow gap-6 hoverable pointer ml-auto ${styles.skillWrapper}`}
            onClick={() => setEditDurationQuestionData(item)}
          >
            <div className={styles.duration}>{calculateMin(questionDuration)}</div>
            <EditIcon />
          </div>
        )}

        {questionTypeId === 2 && (
          <div className={`FlexRow gap-6 ml-auto ${styles.skillWrapper}`}>
            <div className={styles.duration}>{calculateMin(questionDuration)}</div>
          </div>
        )}

        {/* <div className={styles.durationWrapper}>
          <span className={styles.imgWrapper}>
            <img alt="" src={clock} />
          </span>
          <div className={styles.duration}>{calculateMin(questionDuration)}</div>
        </div> */}
      </div>
    </div>
  );
}

const EditIcon = ({ props }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.65543 10.8651L10.2309 6.28957C10.3564 6.16405 10.56 6.16405 10.6855 6.28957L11.8977 7.50175C12.0232 7.62728 12.0232 7.8308 11.8977 7.95632L7.32218 12.5318C7.26531 12.5887 7.1892 12.6222 7.10886 12.6257L5.84157 12.6808C5.65322 12.6889 5.4983 12.534 5.50649 12.3457L5.56159 11.0784C5.56508 10.998 5.59856 10.9219 5.65543 10.8651Z"
      fill="#7458D3"
      stroke="#7458D3"
      stroke-width="0.642857"
    />
    <rect
      x="11.1782"
      y="5.34253"
      width="0.857143"
      height="2.35714"
      rx="0.321429"
      transform="rotate(-45 11.1782 5.34253)"
      fill="white"
      stroke="#7458D3"
      stroke-width="0.642857"
    />
  </svg>
);
