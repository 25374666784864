import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useWindowSize } from '../../../utils/helpers';
import constants from '../../../utils/constants';
import routes from '../../../constant/route';
import styles from '../Styles/AssessmentDetailsModal.module.css';

import CloseButton from '../../../Common/Components/CloseButton';
import AssessmentInfoWrapper from '../Components/AssessmentInfoWrapper/AssessmentInfoWrapper';
import Modal from '../../../Common/CustomModal/Modal';
import MobileModal from '../../../Common/Components/MobileModal/MobileModal';
import TextOverflow from '../../../Common/Components/TextOverflow';
import Loading from '../../../Common/Components/Loading';
import Tooltip from '../../../Common/Components/Tooltip';
import CustomButton from '../../../Common/Components/CustomButton';

import { updateCustomAssessmentAction } from '../../../redux/assessmentsVol2/actions';
import {
  setAssessmentId,
  setCustomAssessment,
  setUpdateCustomAssessmentCheck
} from '../../../redux/assessmentsVol2/slice';
import {
  assessmentActiveTab,
  updateCustomAssessmentCheckRedux
} from '../../../redux/assessmentsVol2/selectors';

import { ReactComponent as GoBack } from '../../../images/AddAssessment/AssessmentDetailsModal/left-button.svg';
import tickIcon from '../../../images/AddAssessment/AssessmentDetailsModal/tick-icon.svg';
import multipleChoiceIcon from '../../../images/AddAssessment/AssessmentDetailsModal/multipleChoiceIcon.svg';
import multipleCorrectIcon from '../../../images/AddAssessment/AssessmentDetailsModal/multipleCorrect.svg';
import freeTextIcon from '../../../images/AddAssessment/AssessmentDetailsModal/freeTextIcon.svg';
import codingIcon from '../../../images/AddAssessment/AssessmentDetailsModal/codingIcon.svg';
import newTabIcon from '../../../images/AddAssessment/AssessmentDetailsModal/newTabIcon.svg';

const WEB_MODAL_THRESHOLD = 914;
const MOBILE_MODAL_THRESHOLD = constants.MOBILE_THRESHOLD;

const CloseButtonWrapper = ({ onHide }) => {
  return (
    <div className={styles.closeButtonWrapper}>
      <CloseButton size="large" onClick={onHide} />
    </div>
  );
};

const QuestionItem = ({ qInfo }) => {
  // const { t } = useTranslation(['common']);
  const openQuestionPreview = () => {
    // window.open(`${routes.questionPreview}?id=${qInfo?.questionId}`, '_blank').focus();
    window.open(routes.question(qInfo?.questionId), '_blank').focus();
  };

  return (
    <div className={`FlexRow gap-10 verticallyCenter ${styles.questionItem}`}>
      <img
        alt="questionTypeIcon"
        src={
          qInfo?.questionTypeName === 'Multiple Choice'
            ? multipleChoiceIcon
            : qInfo?.questionTypeName === 'Free Text'
            ? freeTextIcon
            : qInfo.questionTypeName === 'Multiple Correct'
            ? multipleCorrectIcon
            : codingIcon
        }
      />
      <TextOverflow
        text={qInfo?.questionTitle}
        className={`fw-400 font-16 c-darkGray pointer ${styles.questionTitleText}`}
        onClick={openQuestionPreview}
      />
      <div className={`FlexRow fw-300 font-14 c-darkGray-05 ${styles.questionDifficulty}`}>
        {qInfo?.difficulty}
      </div>
      <img
        src={newTabIcon}
        alt="newTab"
        className={`pointer ${styles.newTabIcon}`}
        onClick={openQuestionPreview}
      />
    </div>
  );
};

const LeftWrapper = ({ qData }) => {
  const [screenW] = useWindowSize();

  const isMobileFlag = screenW <= MOBILE_MODAL_THRESHOLD;
  const isSmallWebFlag = screenW > MOBILE_MODAL_THRESHOLD && screenW <= WEB_MODAL_THRESHOLD;

  const { t } = useTranslation(['addPosition']);

  return (
    <div className={`FlexColumn gap-24 ${isSmallWebFlag || isMobileFlag ? null : styles.leftPane}`}>
      <div className="FlexColumn gap-10">
        <span className="fw-400 font-12 c-darkGray-05">
          {' '}
          {t('assessmentName').toLocaleUpperCase('tr')}{' '}
        </span>
        <TextOverflow
          className="fw-400 font-18 c-darkGray w-100"
          text={qData?.rootAssessmentName}
        />
      </div>
      <div
        className={`FlexColumn gap-10 ${
          isSmallWebFlag || isMobileFlag ? null : styles.detailWrapper
        }`}
      >
        <span className="uppercase fw-400 font-12 c-darkGray-05">
          {' '}
          {t('assessmentDetails').toLocaleUpperCase('tr')}{' '}
        </span>
        <AssessmentInfoWrapper item={qData} />
      </div>
      <div
        className={`FlexColumn gap-10 ${
          isSmallWebFlag || isMobileFlag ? null : styles.skillsWrapper
        }`}
      >
        <span className="uppercase fw-400 font-12 c-darkGray-05"> {t('skills')} </span>
        <div
          className={`FlexColumn gap-16 ${
            isSmallWebFlag || isMobileFlag ? null : styles.skillsScroll
          }`}
        >
          {qData?.skills &&
            qData?.skills?.map((x, index) => (
              <div key={index} className="FlexRow gap-12">
                <img src={tickIcon} alt="tick" />
                <div className="fw-400 font-16 c-darkGray">{x}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const RightWrapper = ({ qData }) => {
  const [screenW] = useWindowSize();

  const isMobileFlag = screenW <= MOBILE_MODAL_THRESHOLD;
  const isSmallWebFlag = screenW > MOBILE_MODAL_THRESHOLD && screenW <= WEB_MODAL_THRESHOLD;

  const { t } = useTranslation(['addPosition']);

  return (
    <div
      className={`FlexColumn gap-12 ${isSmallWebFlag || isMobileFlag ? null : styles.rightPane}`}
    >
      <span className="uppercase fw-400 font-12 c-darkGray-05"> {t('questionDetails')} </span>
      <div
        className={`FlexColumn gap-8 ${
          isSmallWebFlag || isMobileFlag ? null : styles.questionsScroll
        }`}
      >
        {qData &&
          qData?.questionList &&
          qData?.questionList?.map((x, index) => <QuestionItem key={index} qInfo={x} />)}
      </div>
    </div>
  );
};

const ButtonWrapper = ({ isCustomAssessment, assessmentData, continueClick, editOnCoensioLib }) => {
  const [screenW] = useWindowSize();
  const history = useHistory();
  const dispatch = useDispatch();

  // Below 700px assessment customization page becomes unusable
  const isMobileFlag = screenW <= constants.ASSESSMENT_CUSTOMIZATION_THRESHOLD;

  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [continueButtonLoading, setContinueButtonLoading] = useState(false);

  const isSavedRootAssessment = assessmentData?.objStatusId === 1;
  const isUsedRootAssessment = assessmentData?.hasCompanyAssessment;

  const activelyUsedCustomFlag =
    isCustomAssessment && isSavedRootAssessment && isUsedRootAssessment;

  const activeNotUsedCustomFlag =
    isCustomAssessment && isSavedRootAssessment && !isUsedRootAssessment;

  const notSavedFlag = !isSavedRootAssessment;

  const editBehavior = async () => {
    setEditButtonLoading(true);

    // Custom assessment + not customizable -> created by gpt and not yet saved
    if (isCustomAssessment && !assessmentData?.isCustomizable) {
      history.push(`/preview-assessment?assessmentId=${assessmentData?.rootAssessmentId}`);
    } else {
      // Edit enabled + not gpt -> editable
      // eslint-disable-next-line
      if (isCustomAssessment) {
        const data = {
          rootAssessmentId: assessmentData?.rootAssessmentId,
          objStatus: 4
        };
        await dispatch(updateCustomAssessmentAction(data));
      } else {
        // Coensio Library
        dispatch(setAssessmentId(assessmentData?.rootAssessmentId));
        editOnCoensioLib();
      }
    }
    setEditButtonLoading(false);
  };

  const nextBehavior = async () => {
    setContinueButtonLoading(true);
    await continueClick();
    setContinueButtonLoading(false);
  };

  const { t } = useTranslation(['addPosition']);

  return (
    <div className={styles.buttonWrapper}>
      {!isMobileFlag && activelyUsedCustomFlag ? (
        <Tooltip content={t('ActivelyUsedCannotEdited')} direction="top">
          <CustomButton textField={t('edit')} isDisabled size="small" type={5} />
        </Tooltip>
      ) : !isMobileFlag && (activeNotUsedCustomFlag || !isCustomAssessment || notSavedFlag) ? (
        <CustomButton
          textField={
            !isCustomAssessment || assessmentData?.isCustomizable ? t('edit') : t('preview')
          }
          loading={editButtonLoading}
          size="small"
          type={5}
          buttonOnClick={editBehavior}
        />
      ) : null}

      <CustomButton
        textField={isSavedRootAssessment ? t('continue') : t('saveContinue')}
        isDisabled={assessmentData?.questionList?.length === 0}
        tooltipText={t('noQuestionInThisAssm')}
        loading={continueButtonLoading}
        type={1}
        size="small"
        buttonOnClick={nextBehavior}
      />
    </div>
  );
};

const AssessmentDetailsModal = ({ show, assessmentData, onHide, nextStage, onCreate }) => {
  const assessmentTabType = useSelector(assessmentActiveTab);
  const dispatch = useDispatch();
  const history = useHistory();

  const [screenW] = useWindowSize();

  const isMobileFlag = screenW <= MOBILE_MODAL_THRESHOLD;
  const isSmallWebFlag = screenW > MOBILE_MODAL_THRESHOLD && screenW <= WEB_MODAL_THRESHOLD;

  const [innerShowState, setInnerShowState] = useState(show);

  useEffect(() => {
    if (show) {
      setInnerShowState(true);
    } else {
      setTimeout(() => {
        setInnerShowState(false);
      }, 300);
    }
  }, [show]);

  const updateCustomAssessmentCheckFlag = useSelector(updateCustomAssessmentCheckRedux);
  useEffect(() => {
    if (updateCustomAssessmentCheckFlag === true && show) {
      dispatch(setUpdateCustomAssessmentCheck('pending'));
      dispatch(setCustomAssessment(assessmentData?.rootAssessmentId));
      history.push(`/assessment-customization/${assessmentData?.rootAssessmentId}`);
    }
  }, [updateCustomAssessmentCheckFlag]);

  const { t } = useTranslation(['addPosition']);

  const ModalContent = (
    <>
      {assessmentData ? (
        <>
          {!isMobileFlag && <CloseButtonWrapper onHide={onHide} />}
          <div className={styles.contentWrapper}>
            <div
              className={
                isSmallWebFlag || isMobileFlag ? 'FlexColumn overflowAuto gap-24' : 'FlexRow'
              }
              style={
                isSmallWebFlag
                  ? { maxHeight: 'calc(90dvh - 56px - 52px)', paddingRight: '14px' } // maxHeight - padding - buttonWrapper
                  : isMobileFlag
                  ? { padding: '0px 20px 0px 20px' }
                  : {}
              }
            >
              <LeftWrapper qData={assessmentData} />
              <RightWrapper qData={assessmentData} />
            </div>
            <ButtonWrapper
              isCustomAssessment={assessmentTabType === 1}
              assessmentData={assessmentData}
              continueClick={onCreate}
              editOnCoensioLib={nextStage}
            />
          </div>
        </>
      ) : (
        <div
          className="FlexRow verticallyCenter horizontallyCenter"
          style={
            isSmallWebFlag
              ? { minHeight: '40dvh' }
              : isMobileFlag
              ? { height: '80dvh' }
              : { width: '860px' }
          }
        >
          <Loading />
        </div>
      )}
    </>
  );

  const WebModal = (
    <Modal
      show={show}
      centerHorizontally
      centerVertically
      onClose={onHide}
      outsideClickClose
      contentStyle={
        isSmallWebFlag
          ? {
              maxHeight: '90dvh',
              borderRadius: '16px',
              padding: '28px 14px 28px 28px',
              width: '520px'
            }
          : {
              width: 'fit-content',
              minHeight: '422px',
              padding: '28px',
              borderRadius: '16px',
              display: 'flex'
            }
      }
    >
      {ModalContent}
    </Modal>
  );

  const MobileDetailModal = (
    <MobileModal
      id="mobile-assessmentDetailModal"
      outsideClickClose
      show={show}
      onClose={onHide}
      maxHeight="100dvh"
      contentStyle={{
        height: '100dvh'
      }}
    >
      <div
        className={`FlexRow fw-400 font-16 gap-8 verticallyCenter ${styles.mobileGoBackWrapper}`}
      >
        <GoBack onClick={onHide} className={styles.goBackIcon} />
        <span onClick={onHide} style={{ color: 'rgba(116, 88, 211, 0.6)' }}>
          {t('backToAssmLib')}
        </span>
      </div>
      {ModalContent}
    </MobileModal>
  );

  return innerShowState ? (isMobileFlag ? MobileDetailModal : WebModal) : null;
};

export default AssessmentDetailsModal;
