import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './FilesTab.module.css';
import Dropdown from '../../../../Common/Dropdown/Dropdown';
import { ReactComponent as FileUploadIcon } from '../../../Images/file-upload.svg';
import { ReactComponent as DeleteIcon } from '../../../Images/delete.svg';
import { ReactComponent as PersonIcon } from '../../../Images/person.svg';
import { ReactComponent as TimeIcon } from '../../../Images/time.svg';
import {
  getFileCandidateAction,
  uploadFileCandidateAction,
  deleteFileCandidateAction
} from '../../../Api/action';
import { getParam, sanitizedName } from '../../../../utils/helpers';

const FilesTab = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(['candidateDetail']);

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // fetch files from API
    fetchFiles(id);
  }, [id]);

  const fetchFiles = async id => {
    setLoading(true);

    await getFileCandidateAction({ userId: id })
      .then(resp => {
        if (resp && resp.data && resp.data.length > 0) {
          const tempFiles = resp.data.map(file => ({
            id: file.fileId,
            name: file.fileName,
            url: file.fileUrl,
            uploadDate: file.uploadDate,
            uploadedBy: file.name + ' ' + file.surname
          }));
          setFiles(tempFiles);
          if (tempFiles.length > 0) {
            setSelectedFile(tempFiles[0]);
          }
        } else {
          setFiles([]);
          setSelectedFile(null);
        }
      })
      .catch(error => {
        console.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const uploadFiles = async files => {
    const newFiles = Array.from(files);

    // add files type and size validation
    if (newFiles.some(file => file.size > 5 * 1024 * 1024)) {
      alert(t('fileSizeError'));
      return;
    }

    try {
      // Yeni bir FormData nesnesi oluştur
      const formData = new FormData();

      // FormData'ya kullanıcı bilgilerini ekle
      formData.append('userId', id);
      formData.append('companyAssessmentId', getParam(location.search, 'currentAssessmentId'));

      // Her bir dosyayı "Files" adıyla FormData'ya ekle
      newFiles.forEach(file => {
        // Dosya ismini sanitize et
        const sanitizedFileName = sanitizedName(file.name);

        // Dosya adını değiştirilmiş haliyle yeni bir Blob oluştur
        const sanitizedFile = new File([file], sanitizedFileName, { type: file.type });

        // FormData'ya ekle
        formData.append('Files', sanitizedFile);
      });

      // FormData'yı API'ye gönder
      const response = await uploadFileCandidateAction(formData);

      // Yanıtı işleyin
      if (response) {
        setFiles(response.data); // Gerekirse dosya listesini güncelle
        // setFiles(response.data.files); // Gerekirse dosya listesini güncelle
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      // Dosya yükleme işlemi bittiğinde yapılacak işlemler
      // dosyaları yeniden getir
      fetchFiles(id);
    }
  };

  const deleteFile = async e => {
    e.preventDefault();

    if (!selectedFile) {
      return;
    }

    try {
      // Dosya silme işlemi için API'ye istek gönder
      const response = await deleteFileCandidateAction({
        userId: id,
        fileId: selectedFile.id,
        fileUrl: selectedFile.url
      });

      // Yanıtı işleyin
      if (response) {
        // Dosyayı listeden kaldır
        setFiles(files.filter(file => file.id !== selectedFile.id));
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      // Dosya silme işlemi bittiğinde yapılacak işlemler
      // dosyaları yeniden getir
      fetchFiles(id);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.fileSelect}>
        <div className={styles.filesHeader}>{t('candidateFiles')}</div>
        <div className={styles.filesDropdown}>
          <Dropdown // Dropdown component is imported from src/Common/Dropdown/Dropdown.js
            wrapperId="filesDropdown"
            title={t('files')}
            height="5rem"
            wrapperStyle={{ width: '600px', maxWidth: '70%' }}
            list={files}
            placeholder={t('selectFile')}
            openItemCount={3}
            selectedOption={selectedFile}
            setSelectedOption={_ => setSelectedFile(_)}
            disabled={files.length === 0 || loading}
          />
          <label htmlFor="fileUpload" className={styles.uploadButton}>
            <FileUploadIcon />
            {t('upload')}
            <input
              style={{ display: 'none' }}
              id="fileUpload"
              type="file"
              multiple
              accept=".pdf,.doc,.docx"
              onChange={e => {
                uploadFiles(e.target.files);
              }}
            />
          </label>
          <span
            className={`${styles.deleteButton} ${selectedFile ? styles.active : ''}`}
            onClick={deleteFile}
          >
            <DeleteIcon />
            {t('deleteFile')}
          </span>
        </div>
        {selectedFile && (
          <div className={styles.fileInfo}>
            <span className={styles.fileInfoItem}>
              <PersonIcon />
              <span className={styles.fileInfoValue}>{selectedFile?.uploadedBy}</span>
            </span>
            <span className={styles.fileInfoItem}>
              <TimeIcon />
              {selectedFile?.uploadDate &&
                new Date(selectedFile.uploadDate).toLocaleDateString(
                  i18n.language === 'tr' ? 'tr-TR' : 'en-US',
                  {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  }
                )}{' '}
            </span>
          </div>
        )}
      </div>
      {selectedFile ? (
        selectedFile?.url.endsWith('.pdf') ? (
          <div className={styles.viewer}>
            <iframe
              key={selectedFile?.url}
              src={`${process.env.REACT_APP_IMAGE_API}/${
                selectedFile?.url
              }?cacheBuster=${Date.now()}`}
              title="PDF Viewer"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </div>
        ) : selectedFile?.url.endsWith('.doc') || selectedFile?.url.endsWith('.docx') ? (
          <div className={styles.viewer}>
            <iframe
              key={selectedFile?.url}
              src={`https://docs.google.com/gview?url=${
                process.env.REACT_APP_IMAGE_API + '/' + selectedFile?.url
              }&embedded=true&cacheBuster=${Date.now()}`}
              title="Google Docs Viewer"
              width="100%"
              height="100%"
              style={{ border: 'none' }}
            />
          </div>
        ) : (
          <div className={styles.unsupportedFile}>{t('unsupportedFile')}</div>
        )
      ) : null}
    </div>
  );
};

export default FilesTab;
