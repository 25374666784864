/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import styled from 'styled-components';
import {
  getRootAssessmentDropdownListAction,
  publishAssessmentControlAction
} from '../../../redux/addAssessment/actions';
import { userSelector } from '../../../redux/auth/selectors';
import styles from '../Styles/AddAssessmentContent.module.css';
import CustomButton from '../../../Common/Components/CustomButton';
import CustomAssessmentModal from '../Modals/CustomAssessmentModal';
import GoBackButton from '../../../Common/Components/GoBackButton';
import TabSwitch from '../../../Common/Components/TabSwitch';
import Loading from '../../../Common/Components/Loading';
import {
  assessmentActiveTab,
  continueToAssessmentCustomizationRedux,
  filteredRootAssessmentList,
  mostPopularRootAssessmentListRedux,
  selectedRootAssessmentQuestionListRedux,
  updateCustomAssessmentCheckRedux
} from '../../../redux/assessmentsVol2/selectors';
import NameAssessmentModal from '../Modals/NameAssessmentModal';
import {
  setAssessmentActiveTab,
  setAssessmentId,
  setContinueToAssessmentCustomization,
  setFilteredRootAssessmentList,
  setRootQuestionList,
  setSelectedRootAssessmentQuestionList,
  setUpdateCustomAssessmentCheck,
  setSelectedRootAssmFilters
} from '../../../redux/assessmentsVol2/slice';
import DeleteAssessmentModal from '../Modals/DeleteAssessmentModal';
import {
  publishEnabled,
  rootAssmCategories,
  rootAssmJobRoles,
  rootAssmSeniorities
} from '../../../redux/addAssessment/selectors';
import HeadsUpModal from '../../../Auth/Modals/HeadsUpModal';
import { currentPlanRedux, toBePurchasedAddOnRedux } from '../../../redux/plan/selectors';
import ContactSalesSuccess from '../../../Auth/Modals/UnsubscribeSuccessModal';
import { getCurrentPlanAction, getCurrentUsageAction } from '../../../redux/plan/actions';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { ReactComponent as Error } from '../../../images/Common/errorSubmit.svg';
import FreeTrialModal from '../../../Auth/Modals/FreeTrialModal/FreeTrialModal';
import { setToBePurchasedAddOn } from '../../../redux/plan/slice';
import PurchaseAddOnSuccessModal from '../../../Auth/Modals/PurchaseAddOnSuccess/PurchaseAddOnSuccessModal';
import mixpanel from '../../../utils/mixpanel';
import BackgroundImageWrapper from '../../../Common/Components/BackgroundImageWrapper/BackgroundImageWrapper';
import noCustomAssessImg from '../../../images/Common/BackgroundImage/noCustomAssessment.svg';
import CustomSearchbar from '../../../Common/Components/CustomSearchbar/CustomSearchbar';
import RootAssessmentCard from './RootAssessmentCard/RootAssessmentCard';
import leftScroll from './RootAssessmentCard/Images/leftScroll.svg';
import rightScroll from './RootAssessmentCard/Images/rightScroll.svg';
import leftScrollLight from './RootAssessmentCard/Images/leftScrollLight.svg';
import rightScrollLight from './RootAssessmentCard/Images/rightScrollLight.svg';
import Dropdown from '../../../Common/Dropdown/Dropdown';
import noResult from '../../../images/Common/BackgroundImage/noResult-addAssm.svg';
import { fetchRootAssessmentList, useWindowSize } from '../../../utils/helpers';
import { setRootAssmCategories, setRootAssmJobRoles } from '../../../redux/addAssessment/slice';
import FilterIcon from '../Images/filter.svg';
import FilterIconPurple from '../Images/filterPurple.svg';
import purpleCircle1 from '../Images/filter1.svg';
import purpleCircle2 from '../Images/filter2.svg';
import purpleCircle3 from '../Images/filter3.svg';
import MobileAssessmentFilterModal from '../Modals/MobileAssessmentFilterModal';
import useHideKeyboard from '../../../hooks/useHideKeyboard';
import AssessmentDetailsModal from '../Modals/AssessmentDetailsModal';

const CreateAssessmentContent = styled.div`
  padding-top: 1rem !important;

  .title {
    font-size: 36px;
    line-height: 59px;
    letter-spacing: -0.02em;
    color: #585858;
  }

  .subtitle {
    color: rgba(88, 88, 88, 0.8);
    font-size: 24px;
    line-height: 39px;
    letter-spacing: -0.02em;
  }
`;

export default function AddAssessmentContent() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [windowW, windowH] = useWindowSize();
  const [category, setCategory] = React.useState({});
  const user = useSelector(userSelector);
  const selectedRootAssessmentQuestionList = useSelector(selectedRootAssessmentQuestionListRedux);
  const [showCustomModal, setCustomModal] = useState(false);
  const [showAssessmentDetail, setDetail] = useState(false);

  const { t } = useTranslation(['addPosition']);

  // Mobile Filter variables
  const [showMobileFilterModal, setShowMobileFilterModal] = useState(false);
  const [filteredCount, setFilteredCount] = useState(0);
  const [showmobileFilter, setShowMobileFilter] = useState(false);

  // eslint-disable-next-line no-unused-vars

  const tab = useSelector(assessmentActiveTab);
  const [showNameModal, setNameModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const [contactSalesSuccessShow, setContactSalesSuccessShow] = useState(false);
  /*
    React state that keeps the current page for back button functionality.
   */
  const [searchTerm, setSearchTerm] = useState('');

  const publishEnabledCheck = useSelector(publishEnabled);

  const myPlan = useSelector(currentPlanRedux);
  const continueToAssessmentCustomizationFlag = useSelector(continueToAssessmentCustomizationRedux);
  const updateCustomAssessmentCheckFlag = useSelector(updateCustomAssessmentCheckRedux);

  // Variables for controls after "Continue" clicked: assessment availability & pr Q control
  const [assessmentUnavailableInFT, setAssessmentUnavailableInFT] = useState(false);
  const [assessmentUnavailable, setAssessmentUnavailable] = useState(false);
  const toBePurchasedAddOn = useSelector(toBePurchasedAddOnRedux);

  // Variables for mobile success banners of duplicate & delete assessments
  const [duplicated, setDuplicated] = useState(false);
  const [deleted, setDeleted] = useState(false);

  // Conditional render for assm details modal
  const [showAssmDetailModal, setShowAssmDetailModal] = useState(false);
  const [nameAssmShow, setNameAssmShow] = useState(false);

  useEffect(() => {
    if (showNameModal) {
      setNameAssmShow(true);
    } else {
      setTimeout(() => {
        setNameAssmShow(false);
      }, 300);
    }
  }, [showNameModal]);

  useEffect(() => {
    if (showAssessmentDetail) {
      setShowAssmDetailModal(true);
    } else {
      setTimeout(() => {
        setShowAssmDetailModal(false);
      }, 300);
    }
  }, [showAssessmentDetail]);

  useEffect(() => {
    if (showMobileFilterModal) {
      setShowMobileFilter(true);
    } else {
      setTimeout(() => {
        setShowMobileFilter(false);
      }, 300);
    }
  }, [showMobileFilterModal]);

  useEffect(() => {
    dispatch(setContinueToAssessmentCustomization('pending'));
    dispatch(setUpdateCustomAssessmentCheck('pending'));
  }, []);

  async function handleAddAssessment() {
    const data = {};

    const publishControl = await dispatch(publishAssessmentControlAction(data));

    if (publishControl?.payload?.result) {
      history.push(
        `/create-assessment/${selectedRootAssessmentQuestionList.rootAssessmentId}/${1}`
      );
      setDetail(false);
    } else {
      if (myPlan?.name === 'Free Trial') {
        await setDetail(false);
        await setAssessmentUnavailableInFT(true);
      } else {
        const data = {
          bundleItemCode: 10
        };
        await dispatch(setToBePurchasedAddOn(data));
        await setDetail(false);
        await setAssessmentUnavailable(true);
      }
    }
  }
  const rootCategories = useSelector(filteredRootAssessmentList);

  const sendCategoryDetailModal = async category => {
    // await dispatch(GetQuestionList(category.rootAssessmentId));
    rootCategories.forEach(e => {
      e.rootAssesments.forEach(innerE => {
        if (innerE.rootAssessmentId === category.rootAssessmentId) {
          dispatch(setSelectedRootAssessmentQuestionList(innerE));
        }
      });
    });
  };

  const ref = useRef();

  const fetchRootList = async () => {
    const rawData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0
    };

    await fetchRootAssessmentList(rawData, setLoading, ref, dispatch);
  };

  useEffect(() => {
    if (user) {
      fetchRootList();
    }
  }, [tab, user]);

  const updateAssessment = async () => {
    setDuplicated(true);
    await fetchRootList();
  };

  const deleteAssessment = async () => {
    setDeleted(true);
    fetchRootList();
  };

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    // Empty the question list every time we leave the customization page
    dispatch(setRootQuestionList(null));
    dispatch(setAssessmentId(null));
    return () => {
      dispatch(setAssessmentActiveTab(0));
      dispatch(setFilteredRootAssessmentList([]));
    };
  }, []);

  /* ----------- Payment Related Checks Below ----------- */

  // SUCCESS Scenarios for add on purchases
  // 1 - Purchase Assessment
  function goToAddAssessmentFlow() {
    history.push(`/create-assessment/${selectedRootAssessmentQuestionList.rootAssessmentId}/${1}`);
    const data = {
      CompanyId: user?.companyId
    };
    dispatch(getCurrentPlanAction(data));
    dispatch(getCurrentUsageAction({ companyId: user?.companyId }));
  }

  const [assessmentPurchaseSuccess, setAssessmentPurchaseSuccess] = useState(false);
  /* ----------- Payment Related Checks Above ----------- */

  /* ---------------------- Search Related Methods ---------------------- */

  const [filteredList, setFilteredList] = useState([]);

  const filterAssessments = () => {
    const temp = [];
    rootCategories.forEach(e => {
      if (e.categoryName.toLocaleLowerCase('tr').includes(searchTerm.toLocaleLowerCase('tr'))) {
        temp.push(e);
        return;
      }
      const suitableAssessmentList = [];
      e.rootAssesments.forEach(rootAssessment => {
        if (
          rootAssessment.rootAssessmentName
            .toLocaleLowerCase('tr')
            .includes(searchTerm.toLocaleLowerCase('tr'))
        ) {
          suitableAssessmentList.push(rootAssessment);
        }
      });
      if (suitableAssessmentList.length !== 0) {
        const innerCategory = {
          categoryId: e.categoryId,
          categoryName: e.categoryName,
          rootAssesments: suitableAssessmentList
        };
        temp.push(innerCategory);
      }
    });
    setFilteredList(temp);
  };

  const [searchTimerId, setSearchTimerId] = useState(-1);

  useEffect(() => {
    if (searchTerm !== '') {
      if (searchTimerId !== -1) {
        clearTimeout(searchTimerId);
        setSearchTimerId(-1);
      }
      setSearchTimerId(
        setTimeout(() => {
          filterAssessments();
        }, 300)
      );
    } else {
      clearTimeout(searchTimerId);
      setFilteredList(rootCategories);
    }
  }, [searchTerm, rootCategories]);

  /* ---------------------- Search Related Methods Above ---------------------- */

  /* ------------------- Category Slider Related Stuff Below ------------------- */

  const [scrollArray, setScrollArray] = useState([]);
  const [icons, setIcons] = useState(false);
  const [scrollInAction, setScrollInAction] = useState(false);

  function handleScrollIcons(listIndex) {
    const listLength = filteredList[listIndex].rootAssesments.length;
    const lastCard = document
      .getElementById(`card-${listIndex}-${listLength - 1}`)
      ?.getBoundingClientRect();
    const firstCard = document.getElementById(`card-${listIndex}-${0}`)?.getBoundingClientRect();
    const wrapper = document.getElementById(`catWrapper-${listIndex}`)?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;

    const temp = scrollArray;
    if (scrollArray?.length !== 0) {
      if (lastCard.right <= wrapperRight) {
        temp[listIndex].right = false;
        setScrollArray(temp);
      } else {
        temp[listIndex].right = true;
        setScrollArray(temp);
      }

      if (firstCard?.left < wrapperLeft) {
        temp[listIndex].left = true;
        setScrollArray(temp);
      } else {
        temp[listIndex].left = false;
        setScrollArray(temp);
      }

      setIcons(!icons);
    }
    setScrollInAction(false);
  }

  function handleHorizontalScroll(elementId, direction, listIndex) {
    setScrollInAction(true);
    let availableCardCount = 0;
    let halfCard = 0;
    let halfCardLeft = 0;

    const wrapper = document.getElementById(`catWrapper-${listIndex}`)?.getBoundingClientRect();

    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    let scrollX = 0;
    let scrollXleft = 0;

    filteredList[listIndex]?.rootAssesments.every((catElem, index) => {
      const card = document.getElementById(`card-${listIndex}-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardRight < wrapperRight && cardLeft > wrapperLeft) {
        availableCardCount += 1;
      }

      if (windowW > 576) {
        if (cardRight + 14 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      } else {
        if (cardRight + 21 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      }
      return true;
    });

    filteredList[listIndex]?.rootAssesments.every((catElem, index) => {
      const card = document.getElementById(`card-${listIndex}-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardLeft < wrapperLeft && cardRight > wrapperLeft) {
        halfCardLeft = index;
        scrollXleft = wrapperRight - cardRight;
        return false; // works as break
      }
      return true;
    });

    if (windowW > 576) {
      // RIGHT scroll
      if (direction === 'right') {
        document.getElementById(elementId).scrollLeft -= scrollX + 14;
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById(elementId).scrollLeft -= scrollXleft - 14;
      } else {
        document.getElementById(elementId).scrollLeft -= availableCardCount * 451 - 14;
      }
    } else {
      // RIGHT scroll

      if (direction === 'right') {
        if (windowW === 375 && scrollXleft === 0) {
          document.getElementById(elementId).scrollLeft += 335 + 20;
        } else {
          document.getElementById(elementId).scrollLeft -= scrollX + 20;
        }
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById(elementId).scrollLeft -= scrollXleft - 20;
      } else {
        document.getElementById(elementId).scrollLeft -= availableCardCount * 375 - 20;
      }
    }

    setTimeout(() => {
      handleScrollIcons(listIndex);
    }, 800);
  }

  useEffect(() => {
    const temp = [];
    if (filteredList) {
      filteredList.forEach(cat => {
        temp.push({ left: false, right: true });
      });
      setScrollArray(temp);
    }
  }, [filteredList]);

  useEffect(() => {
    if (scrollArray && scrollArray.length !== 0 && !tab) {
      // tab parameter is used to ensure this does not work on Custom tab
      filteredList.forEach((cat, i) => {
        handleScrollIcons(i);
      });
    }
  }, [scrollArray]);

  /* ------------------- Category Slider Related Stuff Above ------------------- */

  /* ----------- Most Popular Related Scroll Methods ----------- */
  const mostPopularRootAssessmentList = useSelector(mostPopularRootAssessmentListRedux);
  const [scrollArrayForMostPopular, setScrollArrayForMostPopular] = useState([]);

  useEffect(() => {
    const temp = [];
    if (mostPopularRootAssessmentList && mostPopularRootAssessmentList.length !== 0) {
      temp.push({ left: false, right: true });
      setScrollArrayForMostPopular(temp);
    }
  }, [mostPopularRootAssessmentList]);

  function handleScrollIconsForMostPopular() {
    const listLength = mostPopularRootAssessmentList.length;
    const lastCard = document
      .getElementById(`card-mostPopular-${listLength - 1}`)
      ?.getBoundingClientRect();
    const firstCard = document.getElementById(`card-mostPopular-${0}`)?.getBoundingClientRect();
    const wrapper = document.getElementById('catWrapper-mostPopular')?.getBoundingClientRect();
    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;

    const temp = scrollArrayForMostPopular;
    if (scrollArrayForMostPopular.length !== 0) {
      if (lastCard?.right <= wrapperRight) {
        temp[0].right = false;
        setScrollArrayForMostPopular(temp);
      } else {
        temp[0].right = true;
        setScrollArrayForMostPopular(temp);
      }

      if (firstCard?.left < wrapperLeft) {
        temp[0].left = true;
        setScrollArrayForMostPopular(temp);
      } else {
        temp[0].left = false;
        setScrollArrayForMostPopular(temp);
      }

      setIcons(!icons);
    }
    setScrollInAction(false);
  }

  function handleHorizontalScrollForMostPopular(direction) {
    setScrollInAction(true);
    let availableCardCount = 0;
    let halfCard = 0;
    let halfCardLeft = 0;

    const wrapper = document.getElementById('catWrapper-mostPopular')?.getBoundingClientRect();

    const wrapperRight = wrapper?.right;
    const wrapperLeft = wrapper?.left;
    let scrollX = 0;
    let scrollXleft = 0;

    mostPopularRootAssessmentList.every((catElem, index) => {
      const card = document.getElementById(`card-mostPopular-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardRight < wrapperRight && cardLeft > wrapperLeft) {
        availableCardCount += 1;
      }

      if (windowW > 576) {
        if (cardRight + 14 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      } else {
        if (cardRight + 21 > wrapperRight) {
          halfCard = index;
          scrollX = wrapperLeft - cardLeft;
          return false; // works as break
        }
      }
      return true;
    });

    mostPopularRootAssessmentList.every((catElem, index) => {
      const card = document.getElementById(`card-mostPopular-${index}`)?.getBoundingClientRect();
      const cardRight = card?.right;
      const cardLeft = card?.left;

      if (cardLeft < wrapperLeft && cardRight > wrapperLeft) {
        halfCardLeft = index;
        scrollXleft = wrapperRight - cardRight;
        return false; // works as break
      }
      return true;
    });

    if (windowW > 576) {
      // RIGHT scroll
      if (direction === 'right') {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollX + 14;
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollXleft - 14;
      } else {
        document.getElementById('scrollable-mostPopular').scrollLeft -=
          availableCardCount * 451 - 14;
      }
    } else {
      // RIGHT scroll
      if (direction === 'right') {
        if (windowW === 375 && scrollXleft === 0) {
          document.getElementById('scrollable-mostPopular').scrollLeft += 335 + 20;
        } else {
          document.getElementById('scrollable-mostPopular').scrollLeft -= scrollX + 20;
        }
      }
      // LEFT scroll below !
      else if (halfCardLeft !== 0) {
        document.getElementById('scrollable-mostPopular').scrollLeft -= scrollXleft - 20;
      } else {
        document.getElementById('scrollable-mostPopular').scrollLeft -=
          availableCardCount * 375 - 20;
      }
    }

    setTimeout(() => {
      handleScrollIconsForMostPopular();
    }, 800);
  }

  useEffect(() => {
    if (scrollArrayForMostPopular && scrollArrayForMostPopular.length !== 0 && !tab) {
      handleScrollIconsForMostPopular();
    }
  }, [scrollArrayForMostPopular]);
  /* ----------- Most Popular Related Scroll Methods Above ----------- */

  const [selectedCategory, selectCategory] = useState();
  const [selectedJobRole, selectJobRole] = useState();
  const [selectedSeniority, selectSeniority] = useState();

  const assmCategories = useSelector(rootAssmCategories);
  const assmJobRoles = useSelector(rootAssmJobRoles);
  const assmSeniorities = useSelector(rootAssmSeniorities);

  useEffect(async () => {
    const data = {};
    await dispatch(getRootAssessmentDropdownListAction(data));
  }, []);

  async function handleJobRoleSelected(item) {
    if (item?.name) {
      const data = { JobRoleId: item?.roleId };
      const a = await dispatch(getRootAssessmentDropdownListAction(data));
      const tempCategory = assmCategories.filter(
        cat => cat.catId === a.payload.selectedCategory
      )[0];
      selectCategory(tempCategory);
    }
  }

  /* ----------------------- Scroll position for kebab menu ----------------------- */

  const [scrollPos, setScrollPos] = useState();

  useEffect(() => {
    if (tab) {
      document.addEventListener('scroll', e => {
        setScrollPos(window.scrollY);
      });
    }
    return () => {
      document.removeEventListener('scroll', e => {
        setScrollPos(window.scrollY);
      });
    };
  }, [tab]);

  // Handles fetch event for assessment filters
  function applyMobileFilter() {
    setSearchTerm('');
    const listData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0,
      CategoryId: selectedCategory?.catId,
      JobRoleId: selectedJobRole?.roleId,
      SeniorityLevel: selectedSeniority?.seniorityId
    };
    dispatch(
      setSelectedRootAssmFilters({
        category: selectedCategory,
        role: selectedJobRole,
        seniority: selectedSeniority
      })
    );
    fetchRootAssessmentList(listData, setLoading, ref, dispatch);
    setTimeout(() => {
      updateFilterButton();
    }, 700);
  }

  function clearAllMobile() {
    setSearchTerm('');
    const listData = {
      companyId: user.companyId,
      isCompanyAssessment: tab !== 0
    };
    fetchRootAssessmentList(listData, setLoading, ref, dispatch);
    setFilteredCount(0);
  }

  // Handles disability of clear all button
  function updateFilterButton() {
    if (selectedCategory && selectedJobRole && selectedSeniority) {
      setFilteredCount(3);
    } else if (
      (selectedCategory && selectedJobRole && !selectedSeniority) ||
      (selectedCategory && selectedSeniority && !selectedJobRole) ||
      (selectedJobRole && selectedSeniority && !selectedCategory)
    ) {
      setFilteredCount(2);
    } else if (
      (selectedCategory && !selectedJobRole && !selectedSeniority) ||
      (!selectedCategory && selectedSeniority && !selectedJobRole) ||
      (selectedJobRole && !selectedSeniority && !selectedCategory)
    ) {
      setFilteredCount(1);
    } else {
      setFilteredCount(0);
    }
  }

  // Hide keyboard
  useHideKeyboard();

  return (
    <>
      {windowW <= 576 && showmobileFilter && (
        <MobileAssessmentFilterModal
          show={showMobileFilterModal}
          onHide={() => setShowMobileFilterModal(false)}
          id="mobile-assessment-filter-modal"
          setLoading={setLoading}
          setfilterNo={setFilteredCount}
          selectedCategory={selectedCategory}
          selectCategory={selectCategory}
          selectedJobRole={selectedJobRole}
          selectJobRole={selectJobRole}
          selectedSeniority={selectedSeniority}
          selectSeniority={selectSeniority}
          applyFilter={applyMobileFilter}
          clearAll={clearAllMobile}
        />
      )}

      <CreateAssessmentContent id="firstAddAssessmentPage" className="firstAddAssessmentPage">
        {/* START of ASSESMENT ADD-ON PURCHASE */}
        <HeadsUpModal
          addOnCode={10}
          show={assessmentUnavailable}
          onHide={() => setAssessmentUnavailable(false)}
          title={t('hello')}
          description={
            !myPlan?.isCustom ? (
              <>
                {publishEnabledCheck && publishEnabledCheck.publishAssessmentUsedCount <= 1 ? (
                  <span>
                    <Trans
                      i18nKey="yourPlanIncludeAssm"
                      values={{
                        price: publishEnabledCheck?.price,
                        assmCount: publishEnabledCheck?.publishAssessmentRightCount
                      }}
                    >
                      {/* {t('yourPlanIncludeAssm')} */}
                      <span>1</span>
                      <span>2</span>
                      <span>3</span>
                      <span>4</span>
                      <span>5</span>
                    </Trans>
                  </span>
                ) : (
                  publishEnabledCheck &&
                  publishEnabledCheck.publishAssessmentUsedCount > 1 && (
                    <span>
                      <Trans
                        i18nKey="yourPlanIncludeAssms"
                        values={{
                          price: publishEnabledCheck?.price,
                          assmCount: publishEnabledCheck?.publishAssessmentRightCount
                        }}
                      >
                        {/* {t('yourPlanIncludeAssms')} */}
                        <span>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                      </Trans>
                    </span>
                  )
                )}
              </>
            ) : (
              // `Your current plan seems to include ${
              //     publishEnabledCheck?.publishAssessmentRightCount
              //   } published assessment${
              //     publishEnabledCheck?.publishAssessmentRightCount > 1 ? `s` : ''
              //   }.

              // Add an additional published assessment only for $${publishEnabledCheck?.price}.`
              // : `Your plan seems to include ${publishEnabledCheck?.publishAssessmentRightCount} published assessments.
              // Please contact our sales team to upgrade your plan.`

              <>
                {publishEnabledCheck && publishEnabledCheck.publishAssessmentUsedCount <= 1 ? (
                  <Trans
                    i18nKey="upgradePlanText"
                    values={{ assmCount: publishEnabledCheck?.publishAssessmentRightCount }}
                  >
                    {t('upgradePlanTextSingularAssm')}
                  </Trans>
                ) : (
                  publishEnabledCheck &&
                  publishEnabledCheck.publishAssessmentUsedCount > 1 && (
                    <Trans
                      i18nKey="upgradePlanText"
                      values={{ assmCount: publishEnabledCheck?.publishAssessmentRightCount }}
                    >
                      {t('upgradePlanTextPluralAssm')}
                    </Trans>
                  )
                )}
              </>
            )
          }
          isCustom={myPlan?.isCustom}
          setSuccess={myPlan?.isCustom ? setContactSalesSuccessShow : setAssessmentPurchaseSuccess}
          tryAgain={() => console.error('try again published assessment')}
        />

        {/* Purchase Assessment AddOn Success */}
        <PurchaseAddOnSuccessModal
          show={assessmentPurchaseSuccess}
          onHide={() => {
            setAssessmentPurchaseSuccess(false);
            goToAddAssessmentFlow();
          }}
          addOnCode={10}
        />

        {/* USED everywhere in this page no need to multiply the code */}
        <ContactSalesSuccess
          icon="tick"
          show={contactSalesSuccessShow}
          onHide={() => setContactSalesSuccessShow(false)}
          title={t('requestReceived')}
          description={t('expertsWillContact')}
        />
        <FreeTrialModal
          show={assessmentUnavailableInFT}
          onHide={() => setAssessmentUnavailableInFT(false)}
          addOnCode={10}
        />
        {/* END of ASSESMENT ADD-ON PURCHASE */}

        <CustomAssessmentModal
          nextStage={() => {
            setNameModal(true);
            setCustomModal(false);
          }}
          show={showCustomModal}
          onHide={() => {
            setCustomModal(false);
          }}
        />
        {nameAssmShow && (
          <NameAssessmentModal
            onDuplicate={updateAssessment}
            prevStage={() => {
              setNameModal(false);
            }}
            show={showNameModal}
            onHide={() => {
              dispatch(setAssessmentId(null));
              setNameModal(false);
            }}
          />
        )}

        <InfoBanner
          bannerStyle={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 24px rgba(134, 134, 134, 0.3)'
          }}
          show={duplicated}
          setStatus={type => {
            setDuplicated(type);
          }}
        />
        {showAssmDetailModal && (
          <AssessmentDetailsModal
            show={showAssessmentDetail}
            assessmentData={category}
            onHide={() => {
              setDetail(false);
              dispatch(setAssessmentId(null));
            }}
            nextStage={() => {
              setNameModal(true);
              setDetail(false);
            }}
            onCreate={async () => {
              await handleAddAssessment();
            }}
          />
        )}

        <InfoBanner
          text={t('anErrorOccurred')}
          show={
            continueToAssessmentCustomizationFlag === false ||
            updateCustomAssessmentCheckFlag === false
          }
          CustomIcon={Error}
          setStatus={type => {
            dispatch(setContinueToAssessmentCustomization('pending'));
            dispatch(setUpdateCustomAssessmentCheck('pending'));
          }}
        />

        <DeleteAssessmentModal
          onDelete={deleteAssessment}
          onHide={() => {
            setDeleteModal(false);
            dispatch(setUpdateCustomAssessmentCheck('pending'));
          }}
          show={deleteModal}
        />

        <InfoBanner
          bannerStyle={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 24px rgba(134, 134, 134, 0.3)'
          }}
          text={t('assessmentDeleted')}
          show={deleted}
          setStatus={type => {
            setDeleted(type);
          }}
        />
        <div className={`row ${styles['first-header']} ${styles.row}`}>
          {windowW > 576 && (
            <GoBackButton onClick={() => history.goBack()} style={{ marginRight: '0px' }} />
          )}
          <div style={{ marginRight: 'auto' }} id="tabSwitchContainer">
            <TabSwitch
              tabNameList={[
                t('coensioLib'),
                // windowW <= 400 ? 'Company Library' : t('myCompanyLib')
                t('myCompanyLib')
              ]}
              onClickFuncList={[
                () => {
                  setLoading(true);
                  setSearchTerm('');
                  selectCategory(null);
                  selectJobRole(null);
                  selectSeniority(null);
                  dispatch(setAssessmentActiveTab(0));
                  dispatch(getRootAssessmentDropdownListAction({}));
                },
                () => {
                  setLoading(true);
                  setSearchTerm('');
                  selectCategory(null);
                  selectJobRole(null);
                  selectSeniority(null);
                  dispatch(setAssessmentActiveTab(1));
                }
              ]}
              height={windowW > 576 ? '40px' : '36px'}
              myStyle={{ fontSize: windowW <= 576 && '14px' }}
            />
          </div>

          <div className={styles.createCustomButtonWrapper}>
            <CustomButton
              customStyle={{
                width: windowW <= 576 ? '36px' : windowW <= 710 ? '42px' : 'auto',
                height: windowW <= 576 && '36px'
              }}
              type={1}
              buttonId="createCustomButtonId"
              textField={windowW > 710 ? t('createCustomAssessment') : '✨'}
              buttonOnClick={() => {
                setCustomModal(true);
                /* Mixpanel Button Click Event */
                mixpanel.track('Create Custom Assessment - Button Click', {
                  'User Id': user.userId,
                  'Company Name': user.companyName,
                  'Name Surname': user.userNameSurname
                });
              }}
            />
          </div>
        </div>

        {/* FILTER ROW */}
        {!tab && windowW > 576 ? (
          <div className={styles.dropdownContainer}>
            <Dropdown
              height="48px"
              list={assmCategories}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isSearchable
              isCancellable
              openItemCount={assmCategories?.length < 4 ? assmCategories?.length : 4}
              searchBarPlaceholder={t('searchDropdown')}
              title={t('category')}
              setSelectedOption={selectCategory}
              selectedOption={selectedCategory}
              crossOnClick={async () => {
                await dispatch(setRootAssmJobRoles([]));
                await setLoading(true);
                selectJobRole(null);
                selectCategory(null);
                dispatch(getRootAssessmentDropdownListAction({}));
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              selectOnClick={async item => {
                await setSearchTerm('');
                await dispatch(setRootAssmJobRoles([]));
                await setLoading(true);
                selectJobRole(null);
                dispatch(getRootAssessmentDropdownListAction({ Category: item.catId }));
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: item.catId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <Dropdown
              height="48px"
              list={assmJobRoles}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isSearchable
              isCancellable
              openItemCount={assmJobRoles?.length < 4 ? assmJobRoles?.length : 4}
              searchBarPlaceholder={t('searchDropdown')}
              title={t('jobRole')}
              setSelectedOption={selectJobRole}
              selectedOption={selectedJobRole}
              selectOnClick={async item => {
                await setSearchTerm('');
                await setLoading(true);
                await dispatch(setRootAssmCategories([]));
                await handleJobRoleSelected(item);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  JobRoleId: item.roleId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              crossOnClick={async () => {
                setLoading(true);
                selectJobRole(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  SeniorityLevel: selectedSeniority?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <Dropdown
              height="48px"
              list={assmSeniorities}
              headerStyle={{ fontSize: '16px' }}
              listStyle={{ fontSize: '16px' }}
              isCancellable
              openItemCount={3}
              placeholder={t('selectSeniority')}
              title={t('seniority')}
              setSelectedOption={selectSeniority}
              selectedOption={selectedSeniority}
              selectOnClick={async item => {
                setSearchTerm('');
                setLoading(true);
                selectSeniority(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  JobRoleId: selectedJobRole?.roleId,
                  SeniorityLevel: item?.seniorityId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
              crossOnClick={async () => {
                setLoading(true);
                selectSeniority(null);
                const listData = {
                  companyId: user.companyId,
                  isCompanyAssessment: tab !== 0,
                  CategoryId: selectedCategory?.catId,
                  JobRoleId: selectedJobRole?.roleId
                };
                await fetchRootAssessmentList(listData, setLoading, ref, dispatch);
              }}
            />
            <CustomSearchbar
              searchbarId="searchBarIdinAddAssm"
              isExtended
              wrapperStyle={{
                height: '48px',
                background: '#FFFFFF',
                border: '1px solid rgba(57, 57, 57, 0.15)',
                borderRadius: '10px'
              }}
              inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
              inputFieldValue={searchTerm}
              inputFieldPlaceholder={t('search')}
              inputFieldOnChange={event => {
                setSearchTerm(event.target.value);
              }}
            />
          </div>
        ) : !tab ? (
          <div className={styles.dropdownContainer}>
            <CustomSearchbar
              searchbarId="searchBarIdinAddAssm"
              isExtended
              wrapperStyle={{
                height: '42px',
                background: '#FFFFFF',
                border: '1px solid rgba(57, 57, 57, 0.15)',
                borderRadius: '10px'
              }}
              inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
              inputFieldValue={searchTerm}
              inputFieldPlaceholder={t('search')}
              inputFieldOnChange={event => {
                setSearchTerm(event.target.value);
              }}
            />
            <div
              className={`${styles.filterButton} ${filteredCount > 0 && styles.active}`}
              onClick={() => setShowMobileFilterModal(true)}
            >
              {filteredCount === 0 ? (
                <img alt="filter-icon" src={FilterIcon} />
              ) : (
                <img alt="filter-icon-p" src={FilterIconPurple} />
              )}
              <div>{t('filter')}</div>
              <div style={{ height: '18px' }}>
                {filteredCount === 1 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-1" src={purpleCircle1} />
                ) : filteredCount === 2 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-2" src={purpleCircle2} />
                ) : filteredCount === 3 ? (
                  <img style={{ verticalAlign: 'baseline' }} alt="filter-3" src={purpleCircle3} />
                ) : (
                  <div style={{ height: '18px', width: '18px' }} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <CustomSearchbar
            searchbarId="searchBarIdinAddAssm"
            isExtended
            wrapperStyle={{
              height: windowW > 576 ? '48px' : '42px',
              background: '#FFFFFF',
              border: '1px solid rgba(57, 57, 57, 0.15)',
              borderRadius: '10px',
              margin: '0.6rem 0rem 2.4rem'
            }}
            inputFieldStyle={{ fontSize: '16px', fontFamily: 'Jost-400' }}
            inputFieldValue={searchTerm}
            inputFieldPlaceholder={t('search')}
            inputFieldOnChange={event => {
              setSearchTerm(event.target.value);
            }}
          />
        )}

        {!loading && rootCategories?.length === 0 && tab ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10rem' }}>
            <BackgroundImageWrapper
              image={noCustomAssessImg}
              header={t('buildYourOwnLibrary')}
              subtext={t('preAssessCandidatesYourOwnWay')}
            />
          </div>
        ) : null}

        {!loading && filteredList?.length === 0 && rootCategories?.length !== 0 ? (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10rem' }}>
            <BackgroundImageWrapper
              image={noResult}
              header={tab === 0 ? t('keepSearching') : t('noAssessmentsFound')}
              subtext={tab === 0 ? t('couldntFindAssessment') : t('makeSureLookingForRightName')}
            />
          </div>
        ) : null}

        {/* Most Popular category for Coensio Library + No search parameter */}
        {!tab && mostPopularRootAssessmentList?.length > 0 && searchTerm === '' ? (
          <div
            key="mostPopularWrapper"
            className={`${styles.categoryWrapper}`}
            id="catWrapper-mostPopular"
          >
            {windowW > 576 ? (
              <div className={styles.headerWrapper}>
                <span className={styles.mainCategory}>🚀 MOST POPULAR</span>
                <div className={styles.scrollWrapper}>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArrayForMostPopular[0]?.left ? leftScroll : leftScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScrollForMostPopular('left');
                        }
                      }}
                      style={{ cursor: scrollArrayForMostPopular[0]?.left ? 'pointer' : 'auto' }}
                    />
                  </span>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArrayForMostPopular[0]?.right ? rightScroll : rightScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScrollForMostPopular('right');
                        }
                      }}
                      style={{ cursor: scrollArrayForMostPopular[0]?.right ? 'pointer' : 'auto' }}
                    />
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.headerWrapper}>
                <span className={styles.scrollIcon}>
                  <img
                    aria-current={icons}
                    alt=""
                    src={scrollArrayForMostPopular[0]?.left ? leftScroll : leftScrollLight}
                    onClick={() => {
                      if (!scrollInAction) {
                        handleHorizontalScrollForMostPopular('left');
                      }
                    }}
                    style={{ cursor: scrollArrayForMostPopular[0]?.left ? 'pointer' : 'auto' }}
                  />
                </span>
                <span className={styles.mainCategory}>🚀 MOST POPULAR</span>

                <span className={styles.scrollIcon}>
                  <img
                    aria-current={icons}
                    alt=""
                    src={scrollArrayForMostPopular[0]?.right ? rightScroll : rightScrollLight}
                    onClick={() => {
                      if (!scrollInAction) {
                        handleHorizontalScrollForMostPopular('right');
                      }
                    }}
                    style={{ cursor: scrollArrayForMostPopular[0]?.right ? 'pointer' : 'auto' }}
                  />
                </span>
                {/* </div> */}
              </div>
            )}
            <div
              className={styles.categoryInnerWrapper}
              id="scrollable-mostPopular"
              onTouchEnd={() => handleScrollIconsForMostPopular()}
            >
              {mostPopularRootAssessmentList?.map((e, i) => (
                <RootAssessmentCard
                  rootAssessmentDetails={e}
                  setSelectedRootAssessmentDetails={setCategory}
                  setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                  setDetailsModalState={setDetail}
                  setDeleteModalState={setDeleteModal}
                  setDuplicateModalState={setNameModal}
                  wrapperStyle={{ marginTop: '10px', marginBottom: '10px' }}
                  cardId={`card-mostPopular-${i}`}
                />
              ))}
            </div>
          </div>
        ) : null}

        {/* Coensio Library - categories with sliders */}
        {!tab &&
          filteredList?.map((assess, index) => (
            <div key={index} className={`${styles.categoryWrapper}`} id={`catWrapper-${index}`}>
              {windowW > 576 ? (
                <div className={styles.headerWrapper}>
                  <span className={styles.mainCategory}>{assess.categoryName}</span>
                  <div className={styles.scrollWrapper}>
                    <span className={styles.scrollIcon}>
                      <img
                        aria-current={icons}
                        alt=""
                        src={scrollArray[index]?.left ? leftScroll : leftScrollLight}
                        onClick={() => {
                          if (!scrollInAction) {
                            handleHorizontalScroll(`scrollable-${index}`, 'left', index);
                          }
                        }}
                        style={{ cursor: scrollArray[index]?.left ? 'pointer' : 'auto' }}
                      />
                    </span>
                    <span className={styles.scrollIcon}>
                      <img
                        aria-current={icons}
                        alt=""
                        src={scrollArray[index]?.right ? rightScroll : rightScrollLight}
                        onClick={() => {
                          if (!scrollInAction) {
                            handleHorizontalScroll(`scrollable-${index}`, 'right', index);
                          }
                        }}
                        style={{ cursor: scrollArray[index]?.right ? 'pointer' : 'auto' }}
                      />
                    </span>
                  </div>
                </div>
              ) : (
                <div className={styles.headerWrapper}>
                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArray[index]?.left ? leftScroll : leftScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScroll(`scrollable-${index}`, 'left', index);
                        }
                      }}
                      style={{ cursor: scrollArray[index]?.left ? 'pointer' : 'auto' }}
                    />
                  </span>
                  <span className={styles.mainCategory}>{assess.categoryName}</span>

                  <span className={styles.scrollIcon}>
                    <img
                      aria-current={icons}
                      alt=""
                      src={scrollArray[index]?.right ? rightScroll : rightScrollLight}
                      onClick={() => {
                        if (!scrollInAction) {
                          handleHorizontalScroll(`scrollable-${index}`, 'right', index);
                        }
                      }}
                      style={{ cursor: scrollArray[index]?.right ? 'pointer' : 'auto' }}
                    />
                  </span>
                </div>
              )}

              <div
                className={styles.categoryInnerWrapper}
                onTouchEnd={() => handleScrollIcons(index)}
                id={`scrollable-${index}`}
              >
                {Array.isArray(assess.rootAssesments) &&
                  assess.rootAssesments.map((cat, innerIndex) => (
                    <>
                      <RootAssessmentCard
                        rootAssessmentDetails={cat}
                        isCustom={tab}
                        setSelectedRootAssessmentDetails={setCategory}
                        setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                        setDetailsModalState={setDetail}
                        setDeleteModalState={setDeleteModal}
                        setDuplicateModalState={setNameModal}
                        wrapperStyle={{
                          marginTop: '10px',
                          marginBottom: '10px'
                        }}
                        cardId={`card-${index}-${innerIndex}`}
                      />
                    </>
                  ))}
              </div>
            </div>
          ))}

        {/* My Company Library (Custom) - categories with scrolldown */}
        {tab
          ? filteredList?.map((assess, index) => (
              <div
                key={index}
                className={`${styles.CustomCategoryWrapper}`}
                id={`catWrapperCustom-${index}`}
              >
                <div className={styles.headerWrapper}>
                  <span className={styles.mainCategory}>
                    {t('customAssm').toLocaleUpperCase('tr')}
                  </span>
                </div>
                <div className={styles.CustomWrapper}>
                  {assess?.rootAssesments?.map((cat, innerIndex) => (
                    <div className={styles.CustomCardWrapper}>
                      <RootAssessmentCard
                        rootAssessmentDetails={cat}
                        isCustom={tab}
                        setSelectedRootAssessmentDetails={setCategory}
                        setSelectedRootAssessmentQuestionList={sendCategoryDetailModal}
                        setDetailsModalState={setDetail}
                        setDeleteModalState={setDeleteModal}
                        setDuplicateModalState={setNameModal}
                        wrapperStyle={{ margin: '12px 0px' }}
                        cardId={`card-${index}-${innerIndex}`}
                        scrollPos={scrollPos}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          : null}

        {loading && (
          <div className="pb-5">
            <div
              className="d-flex justify-content-center"
              style={{
                alignItems: 'center',
                height: 'calc(100vh - 280px)'
              }}
            >
              <Loading />
            </div>
          </div>
        )}
      </CreateAssessmentContent>
    </>
  );
}
