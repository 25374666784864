import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { userSelector } from '../redux/auth/selectors';
import companyList from '../constant/edgeCaseHelper';

export function useIp() {
  const locationData = JSON.parse(localStorage.getItem('locationData'));
  const { i18n } = useTranslation();
  const [data, setData] = useState(locationData);
  const user = useSelector(userSelector);

  const getDataFromIp = async () => {
    /* To avoid excess request to ipapi, we store location data in redux 
        Whenever page is refreshed, a new request will be sent
        After each request, even if an error occurred, locationDataRedux will be set,
            that is why we look for locationDataRedux.currency for upcoming api requests
    */

    if (
      user?.companyName &&
      companyList.EXCLUDED_COMPANIES_FOR_CURRENCY.includes(user?.companyName)
    ) {
      setData({ country: 'TR', currency: 'TRY' });
    } else if (locationData) {
      setData(locationData);
    } else {
      // Connect ipapi.co with fetch()
      // const response = await fetch('https://ipapi.co/json/');
      await new Promise(() => {
        axios
          .get(`https://ipapi.co/json/?key=${process.env.REACT_APP_IPAPI_API_KEY}`, {
            timeout: 3000
          })
          .then(resolve => {
            localStorage.setItem('locationData', JSON.stringify(resolve?.data));
            if (resolve?.data?.country === 'TR') {
              i18n.changeLanguage('tr');
            } else {
              i18n.changeLanguage('en');
            }
            setData(resolve?.data);
          })
          .catch(() => {
            setData(null);
          });
      });
    }
  };

  // Run `getIP` function above just once when the page is rendered
  useEffect(() => {
    if (!data) {
      getDataFromIp();
    }
  }, []);

  return [data, data?.currency || 'TRY', setData];
}
