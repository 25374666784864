import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


import styles from '../Styles/QuestionDescTextEditor.module.css';
import { questionDescriptionRedux } from '../../../redux/addQuestion/selectors';
import { setQuestionDescriptionRedux } from '../../../redux/addQuestion/slice';
import { imageSaveAction } from '../../../Common/Api/actions';

export default function QuestionDescTextEditor() {
  const dispatch = useDispatch();
  const reduxQuestionDescription = useSelector(questionDescriptionRedux);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const handleEditorChange = state => {
    setEditorState(state);
    dispatch(setQuestionDescriptionRedux(draftToHtml(convertToRaw(state.getCurrentContent()))));
  };

  // useEffect(() => {
  //   const blocksFromHtml = htmlToDraft(reduxQuestionDescription);
  //   const { contentBlocks, entityMap } = blocksFromHtml;
  //   const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  //   setEditorState(EditorState.createWithContent(contentState));
  // }, []);

  useEffect(() => {
    const currentEditorHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    // 1) Redux'ta text yoksa ve Editör dolu ise => Editor'ü boşalt
    if (!reduxQuestionDescription && currentEditorHtml) {
      setEditorState(EditorState.createEmpty());
    }
    // 2) Redux'ta text varsa ve Editör'ün HTML'i farklıysa => Editor'ü Redux değeriyle yeniden kur
    else if (
      reduxQuestionDescription &&
      reduxQuestionDescription !== currentEditorHtml
    ) {
      const blocksFromHtml = htmlToDraft(reduxQuestionDescription);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [reduxQuestionDescription]);

  function uploadImage(file) {
    return new Promise((resolve, reject) => {
      const body = new FormData();
      body.append('file', file);
      body.append('directoryName', 'questiondescriptionimages');
      imageSaveAction(body)
        .then(resp => {
          if (resp?.status === 200) {
            const path = `${process.env.REACT_APP_IMAGE_API}${resp?.data?.imageUrl}`;
            resolve({ data: { link: path } });
          }
        })
        .catch(reject);
    });
  }

  return (
    <div id="wrapper" className={styles.MainWrapper}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbar={{
          image: {
            urlEnabled: false,
            uploadEnabled: true,
            previewImage: true,
            uploadCallback: uploadImage
          }
        }}
        toolbarClassName={styles.Toolbar}
        wrapperClassName={styles.Wrapper}
        editorClassName={styles.Editor}
      />
    </div>
  );
}
