import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCandidateAssessmentPublicLabelsService,
  getCandidateIsScoredService,
  getCandidatePublicInfoService,
  getCandidatePublicSolutionsService
} from './service';

export const getCandidateAssessmentPublicLabelsAction = createAsyncThunk(
  'candidatePublic/filteredCandidateAssessmentLabels',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateAssessmentPublicLabelsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCandidatePublicInfoAction = createAsyncThunk(
  'candidatePublic/currentUserDetail',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidatePublicInfoService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCandidatePublicSolutionsAction = createAsyncThunk(
  'candidatePublic/filteredCandidatePublicSolutions',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidatePublicSolutionsService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getCandidateisScoredAction = createAsyncThunk(
  'candidatePublic/filteredCandidateIscored',
  async (data, thunkAPI) => {
    try {
      const response = await getCandidateIsScoredService(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
