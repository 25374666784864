import Dropdown from 'react-dropdown';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../Styles/QuestionDetail.module.css';
import {
  assessmentLabelRedux,
  categoryRedux,
  difficultyRedux,
  languageRedux,
  limitRedux,
  multiSelectAnswersRedux,
  questionDescriptionRedux,
  selectedOptionIndexRedux,
  statusRedux,
  subcategoryRedux,
  titleRedux
} from '../../../redux/addQuestion/selectors';
import {
  setAssessmentLabelRedux,
  setCategoryRedux,
  setDifficultyRedux,
  setLanguageRedux,
  setLimitRedux,
  setStatusRedux,
  setSubcategoryRedux,
  setTitleRedux,
  setQuestionDescriptionRedux,
  setMultiSelectAnswersRedux,
  setSelectedOptionIndexRedux
} from '../../../redux/addQuestion/slice';

import {
  getCreateQuestionAssessmentLabelDropdownAction,
  getCreateQuestionDropdownAction,
  getCreateQuestionSubcategoryDropdownAction,
  analyzeQuestionAction
} from '../../../redux/addQuestion/actions';
import route from '../../../constant/route';
import CustomButton from '../../../Common/Components/CustomButton';
import AnalysisModal from './AnalysisModal';

function QuestionDetails({ detailChange }) {
  const dispatch = useDispatch();

  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [difficultyList, setDifficultyList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [limitList, setLimitList] = useState([]);


  const [isCoding, setIsCoding] = useState(false);
  const [questionType, setQuestionType] = useState(null);

  const [suggestionModal, setSuggestionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [analysisData, setAnalysisData] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    switch (path) {
      case route.multiSelectQuestion:
        setQuestionType('multiSelect');
        break;
      case route.freeTextQuestion:
        setQuestionType('freeText');
        break;
      default:
        setQuestionType(null);
        break;
    }
  }, []);

  useEffect(() => {
    const path = window.location.pathname;
    if (path === route.sqlQuestion || path === route.codingQuestion) {
      setIsCoding(true);
    }
  }, []);

  // Selectors
  const reduxCategory = useSelector(categoryRedux);
  const reduxAssessmentLabel = useSelector(assessmentLabelRedux);
  const reduxSubcategory = useSelector(subcategoryRedux);
  const reduxTitle = useSelector(titleRedux);
  const reduxDifficulty = useSelector(difficultyRedux);
  const reduxLanguage = useSelector(languageRedux);
  const reduxLimit = useSelector(limitRedux);
  const reduxStatus = useSelector(statusRedux);

  const reduxQuestionDescription = useSelector(questionDescriptionRedux);
  const reduxMultiSelectAnswers = useSelector(multiSelectAnswersRedux);
  const reduxSelectedOptionIndex = useSelector(selectedOptionIndexRedux);

  async function fetchDropdowns() {
    const resp = await dispatch(getCreateQuestionDropdownAction());
    setCategoryList(resp.payload.categoryList);
    setDifficultyList(resp.payload.difficultyList);
    setLanguageList(resp.payload.languageList);
    setLimitList(resp.payload.timeList);
  }

  // const handleUpdateQuestion = () => {
  //   setQuestionDescriptionRedux();
  // };

  const claudeQuestionsCheck = async () => {
    if (!reduxQuestionDescription) {
      alert('Lütfen önce soru içeriğini giriniz.');
      return;
    }
    const questionBodyArray = reduxQuestionDescription.replaceAll('"', '\\"').split('<p>');
    let updatedQuestionBody = '"';

    questionBodyArray.forEach((element, index) => {
      if (index === questionBodyArray.length - 1) {
        updatedQuestionBody = updatedQuestionBody.concat(element.trimEnd(' '));
      } else {
        updatedQuestionBody = updatedQuestionBody.concat(element);
        updatedQuestionBody = updatedQuestionBody.concat(
          '<p class=\\"text-dark-75 font-size-xlg font-weight-normal pt-5 mb-1\\">'
        );
      }
    });

    updatedQuestionBody = updatedQuestionBody.concat('"');
    updatedQuestionBody = updatedQuestionBody.replaceAll('>\n<', '><');

    const rawData =
      questionType === 'multiSelect'
        ? {
          type: 1,
          data: {
            Title: reduxTitle,
            Time: reduxLimit,
            Category: reduxCategory,
            SubCategory: reduxSubcategory,
            Difficulty: reduxDifficulty,
            Language: reduxLanguage,
            DescHtmlJson: updatedQuestionBody,
            OptionsJson: JSON.stringify(reduxMultiSelectAnswers),
            OptionAnswer: reduxSelectedOptionIndex,
            IsActive: reduxStatus
          }
        }
        : questionType === 'freeText'
          ? {
            type: 3,
            data: {
              Title: reduxTitle,
              Time: reduxLimit,
              Category: reduxCategory,
              SubCategory: reduxSubcategory,
              Difficulty: reduxDifficulty,
              Language: reduxLanguage,
              DescHtmlJson: updatedQuestionBody,
              IsActive: reduxStatus
            }
          }
          : null;


    setSuggestionModal(true);
    setIsLoading(true);

    await analyzeQuestionAction(rawData)
      .then(response => {
        setAnalysisData(response.data.analysis);
      })
      .catch(error => {
        console.error(error);
        alert('Hata', error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleApplySuggestions = () => {
    if (!analysisData) return;

    
    if (analysisData?.suggestion) {

      const { suggestionTitle, suggestionQuestion, suggestionOptions, suggestionCorrectOption } =
        analysisData.suggestion;

      if (suggestionTitle) {
        dispatch(setTitleRedux(suggestionTitle));
      }
      if (suggestionQuestion) {
        dispatch(setQuestionDescriptionRedux(suggestionQuestion));
      }

      // MultiSelect durumu var ise...
      if (suggestionOptions) {
        dispatch(setMultiSelectAnswersRedux(suggestionOptions));  // Örnek
        dispatch(setSelectedOptionIndexRedux(suggestionCorrectOption)); // Örnek
      }
    }

    // 3) Modal’ı kapat vs.
    setSuggestionModal(false);
  };

  async function fetchSubcategoryDropdown() {
    const rawData = {
      Category: reduxCategory
    };
    const resp = await dispatch(getCreateQuestionSubcategoryDropdownAction(rawData));
    setSubcategoryList(resp.payload.subcategoryList);
  }

  async function fetchAssessmentListDropdown() {
    const rawData = {
      Category: reduxCategory,
      SubCategory: reduxSubcategory
    };
    const resp = await dispatch(getCreateQuestionAssessmentLabelDropdownAction(rawData));
    dispatch(setAssessmentLabelRedux(resp.payload.assessmentLabel));
  }

  useEffect(() => {
    fetchDropdowns();
  }, []);

  useEffect(() => {
    if (reduxCategory && !isCoding) {
      fetchSubcategoryDropdown();
    }
  }, [reduxCategory]);

  useEffect(() => {
    if (reduxSubcategory && !isCoding) {
      fetchAssessmentListDropdown();
    }
  }, [reduxSubcategory]);

  const onHide = () => {
    setSuggestionModal(false);
    setIsLoading(false);
  };

  return (
    <div className={styles.QuestionDetailsWrapper}>
      <p>
        <b>Question Details</b>
      </p>
      <div className={`${styles.FormInput}`}>
        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Title</div>
          <div className={styles.DropdownWrapper}>
            <input
              placeholder="Question Title"
              type="text"
              onChange={val => {
                dispatch(setTitleRedux(val.target.value));
                detailChange(1);
              }}
              value={reduxTitle}
              style={{ width: '100%', height: '90%', paddingLeft: '9px' }}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Specify the time limit (seconds)</div>
          <div
            className={styles.DropdownWrapper}
            style={limitList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="limit"
              options={limitList}
              onChange={val => {
                dispatch(setLimitRedux(val.value));
                detailChange(1);
              }}
              value={reduxLimit.toString()}
              disabled={limitList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Category</div>
          <div
            className={styles.DropdownWrapper}
            style={categoryList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="category"
              options={categoryList}
              onChange={val => {
                dispatch(setCategoryRedux(val.value));
                dispatch(setSubcategoryRedux(''));
                setSubcategoryList([]);
                dispatch(setAssessmentLabelRedux(''));
                detailChange(1);
              }}
              value={reduxCategory}
              disabled={categoryList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow} style={{ display: isCoding && 'none' }}>
          <div className={styles.NameWrapper}>Sub-category</div>
          <div
            className={styles.DropdownWrapper}
            style={subcategoryList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="subcategory"
              options={subcategoryList}
              onChange={val => {
                dispatch(setSubcategoryRedux(val.value));
                dispatch(setAssessmentLabelRedux(''));
                detailChange(1);
              }}
              value={reduxSubcategory}
              disabled={subcategoryList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow} style={{ display: isCoding && 'none' }}>
          <div className={styles.NameWrapper}>Assessment Label</div>
          <div
            className={styles.DropdownWrapper}
            style={reduxAssessmentLabel ? {} : { opacity: 0.4 }}
          >
            <input
              placeholder="Assessment Label"
              type="text"
              disabled
              value={reduxAssessmentLabel}
              style={{ width: '100%', height: '90%', paddingLeft: '9px' }}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Difficulty Level</div>
          <div
            className={styles.DropdownWrapper}
            style={difficultyList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="difficulty"
              options={difficultyList}
              onChange={val => {
                dispatch(setDifficultyRedux(val.value));
                detailChange(1);
              }}
              value={reduxDifficulty}
              disabled={difficultyList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Question Language</div>
          <div
            className={styles.DropdownWrapper}
            style={languageList.length === 0 ? { opacity: '0.4' } : {}}
          >
            <Dropdown
              className="language"
              options={languageList}
              onChange={val => {
                dispatch(setLanguageRedux(val.value));
                detailChange(1);
              }}
              value={reduxLanguage}
              disabled={languageList.length === 0}
            />
          </div>
        </div>

        <div className={styles.IndividiualRow}>
          <div className={styles.NameWrapper}>Status</div>
          <div className={styles.RadioButtonWrapper}>
            <div className={styles.IndividualRadioButton}>
              <input
                style={{ marginRight: '5px' }}
                type="radio"
                value="Active"
                name="status"
                checked={reduxStatus}
                onChange={() => dispatch(setStatusRedux(true))}
              />{' '}
              Active
            </div>
            <div className={styles.IndividualRadioButton}>
              <input
                style={{ marginRight: '5px' }}
                type="radio"
                value="Passive"
                name="status"
                checked={!reduxStatus}
                onChange={() => dispatch(setStatusRedux(false))}
              />{' '}
              Passive
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px', marginTop: '20px', padding: '12px', backgroundColor: '#f9f9f9', borderRadius: '8px', border: '1px solid #ddd', }}>
          <CustomButton
            customStyle={{
              height: '40px',
              backgroundColor: '#ac542f',
              color: '#ffffff',
              border: 'none',
              padding: '0 16px',
              borderRadius: '6px',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              minWidth: '120px',
            }}
            type={5}
            textField="Claude Check"
            buttonOnClick={() => {
              claudeQuestionsCheck();
            }}
          />
          <div className={styles.modalContainer}>
            <AnalysisModal
              isOpen={suggestionModal}
              onClose={onHide}
              analysis={analysisData}
              onUpdate={handleApplySuggestions}
              onSaveWithoutUpdate={onHide}
              isLoading={isLoading}
            />
          </div>

        </div>
      </div>
    </div>
  );
}

export default QuestionDetails;
