import client from '../../constant/client';
import API from '../../constant/apiVol2';

export const postRunCodeService = data =>
  new Promise((resolve, reject) => {
    client.post(API.postRunCode, data).then(resolve).catch(reject);
  });

  export const getCreateQuestionDropdownService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCreateQuestionDropdown, data).then(resolve).catch(reject);
  });

  export const getCreateQuestionSubcategoryDropdownService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCreateQuestionSubcategoryDropdown, {params: data}).then(resolve).catch(reject);
  });
  
  export const getCreateQuestionAssessmentLabelDropdownService = data =>
  new Promise((resolve, reject) => {
    client.get(API.getCreateQuestionAssessmentLabelDropdown, {params: data}).then(resolve).catch(reject);
  });

  export const createMultipleChoiceQuestionService2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.createMultipleChoiceQuestion, data).then(resolve).catch(reject);
  });

  export const createMultipleCorrectQuestionService2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.createMultipleCorrectQuestion, data).then(resolve).catch(reject);
  });
  
  export const createFreeTextQuestionService2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.createFreeTextQuestion, data).then(resolve).catch(reject);
  });
  
  export const createCodingQuestionService2 = data =>
  new Promise((resolve, reject) => {
    client.post(API.createCodingQuestion, data).then(resolve).catch(reject);
  });
  
  export const postSqlRunCodeService = data =>
  new Promise((resolve, reject) => {
    client.post(API.postSqlRunCode, data).then(resolve).catch(reject);
  });

  export const createSqlQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.createSqlQuestion, data).then(resolve).catch(reject);
  });

  export const analyzeQuestionService = data =>
  new Promise((resolve, reject) => {
    client.post(API.analyzeQuestion, data).then(resolve).catch(reject);
  });

  
  