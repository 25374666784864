import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import styles from './ProfileTab.module.css';
import edit from '../../../../images/Auth/Settings/billingEdit.svg';
import logout from '../../../../images/Auth/Settings/logout.svg';
import { logoutAndRedirect, useWindowSize } from '../../../../utils/helpers';
import { userSelector } from '../../../../redux/auth/selectors';
import { getProfileAction } from '../../../../redux/plan/actions';
import EditUserModal from '../Modals/EditUserModal';
import { profileObjSelector } from '../../../../redux/plan/selectors';
import Skeleton from '../../../../Common/Components/Skeleton';
import Dropdown from '../../../../Common/Dropdown/Dropdown';
import { useIp } from '../../../../hooks/useIp';
import companyList from '../../../../constant/edgeCaseHelper';

const ProfileCard = ({ name, email, surname, onClick }) => {
  const { t } = useTranslation(['myProfileTab']);

  return (
    <div className={styles.profileCardContainer}>
      <div className={styles.profileCardHeader}>
        <span>{t('myProfile').toUpperCase()}</span>
        <div className={styles.iconWrapper} onClick={onClick}>
          <img src={edit} alt="editProfile" className={styles.editIcon} />
        </div>
      </div>
      <div className={styles.profileDetails}>
        <div className={styles.profileIcon}>
          {name && `${name?.charAt(0)}${surname?.charAt(0)}`}
        </div>
        <div className={styles.name}>{`${name || ''} ${
          surname ? surname?.charAt(0) + surname?.slice(1)?.toLocaleLowerCase('tr') : ''
        }`}</div>
        <div className={styles.email}>{email}</div>
      </div>
    </div>
  );
};

const LanguageSelector = () => {
  const { t, i18n } = useTranslation(['myProfileTab']);
  const [data, curreny, setData] = useIp();

  const user = useSelector(userSelector);

  const [lng, setLng] = useState(null);

  useEffect(() => {
    setLng({
      value: i18n.language,
      name: i18n.language === 'tr' ? 'Türkçe' : 'English'
    });
  }, [i18n.language]);

  useEffect(() => {
    if (lng) {
      i18n.changeLanguage(lng.value);
    }
  }, [lng]);

  // get only location data
  const getOnlyLocationData = async () => {
    if (
      user?.companyName &&
      companyList.EXCLUDED_COMPANIES_FOR_CURRENCY.includes(user?.companyName)
    ) {
      setData({ country: 'TR', currency: 'TRY' });
    } else {
      await new Promise(() => {
        axios
          .get(`https://ipapi.co/json/?key=${process.env.REACT_APP_IPAPI_API_KEY}`, {
            timeout: 3000
          })
          .then(resolve => {
            localStorage.setItem('locationData', JSON.stringify(resolve?.data));
            setData(resolve?.data);
          })
          .catch(() => {
            setData(null);
          });
      });
    }
  };

  const langList = [
    {
      value: 'en',
      name: 'English'
    },
    {
      value: 'tr',
      name: 'Türkçe'
    }
  ];

  return (
    <div className={styles.langCardContainer}>
      <div className={styles.langCardHeader}>
        <span>{t('language')}</span>
      </div>
      <div
        style={{
          marginBottom: '10px',
          marginTop: '10px',
          fontSize: '14px',
          fontFamily: 'Jost-400'
        }}
      >
        {t('chooseLanguage')}
      </div>
      <div>
        <Dropdown
          height="48px"
          headerStyle={{ fontSize: '16px' }}
          listStyle={{ fontSize: '16px' }}
          title="Language"
          list={langList}
          selectedOption={lng}
          setSelectedOption={e => {
            if (e.value !== lng.value) {
              getOnlyLocationData();
            }
            setLng(e);
          }}
        />
      </div>
    </div>
  );
};

const ProfileTab = () => {
  const user = useSelector(userSelector);
  const profile = useSelector(profileObjSelector);
  const [modal, setModal] = useState(false);
  const [width] = useWindowSize();
  const dispatch = useDispatch();

  const { t } = useTranslation(['myProfileTab']);

  useEffect(() => {
    if (user) {
      const rawData = {
        UserId: user.userId
      };
      dispatch(getProfileAction(rawData));
    }
  }, [user]);

  return (
    <>
      {profile ? (
        <>
          <div className={styles.contentWrapper}>
            <div className={styles.title}>{t('myProfile')}</div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: width > 940 ? 'row' : 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '32px'
              }}
            >
              <ProfileCard
                name={profile?.name}
                email={profile?.email}
                surname={profile?.surname}
                onClick={() => setModal(!modal)}
              />
              <LanguageSelector />
            </div>
            <div
              className={styles.row}
              onClick={() => {
                logoutAndRedirect();
              }}
            >
              <img src={logout} alt="logout" />
              <div className={styles.logout}>{t('logout')}</div>
            </div>
            <EditUserModal
              show={modal}
              onClose={() => setModal(false)}
              user={profile}
              type="profile"
            />
          </div>
        </>
      ) : (
        <Skeleton type="settingsProfile" />
      )}
    </>
  );
};

export default ProfileTab;
