import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation, Trans } from 'react-i18next';

import styles from './Experience.module.css';
import { ReactComponent as EducationIcon } from './educationIcon.svg';
import { candidateBackgroundSelector } from '../../../../redux/candidateDetail/selectors';
import CustomButton from '../../../../Common/Components/CustomButton';

import LinkedinSchoolLogo from "./Images/linkedin-school-default.png";

const Education = () => {
  const education = useSelector(candidateBackgroundSelector);
  const [eduNoAvailable, setEduNoAvailable] = useState(2);

  const { t } = useTranslation(['candidateDetail'])

  return (
    <>
      {education?.educations ? (
        <div className={styles.wrapper}>
          <span className={styles.header}>{t('education')}</span>

          {education?.educations?.slice(0, eduNoAvailable)?.map((edu, i) => (
            <div className={styles.rowWrapper}>
              <div className={styles.companyLogo}>
                {edu?.schoolLogo && edu?.schoolLogo !== 'Coensio' ? (
                  <img
                    className={styles.logoImg}
                    src={edu?.schoolLogo}
                    alt={`schoolLogo-${i}`}
                    onError={e => {
                      e.target.src = LinkedinSchoolLogo;
                    }}
                  />
                ) : (
                  <EducationIcon />
                )}
              </div>

              <div className={styles.textWrapper}>
                <span className={styles.subHeader}>{edu?.degree}</span>
                <span className={styles.companyText}>{edu?.schoolName}</span>
                <span className={styles.experienceDate}>{edu?.date}</span>
              </div>
            </div>
          ))}
          {education?.educations?.length > 2 && eduNoAvailable !== education?.educations?.length ? (
            <CustomButton
              type={5}
              textField={
                <span>
                  <Trans
                    i18nKey="showAllEducation"
                    ns="candidateDetail"
                    values={{ educationsCount: education?.educations?.length }}
                  >
                    0<span>1</span>
                  </Trans>
                </span>
              }
              customStyle={{ width: '100%' }}
              size="small"
              buttonOnClick={() => setEduNoAvailable(education?.educations?.length)}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Education;
