import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { useTranslation, Trans } from 'react-i18next';

import styles from './ForgotPasswordSuccessComponent.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import { ReactComponent as Arrow } from '../../Images/backIcon.svg';
import route from '../../../constant/route';

import { forgotPasswordAction } from '../../../redux/signup/actions';
import InfoBanner from '../../../Common/Components/InfoBanner';
import { useInterval } from '../../../utils/useInterval';
import { setForgotPasswordStep } from '../../../redux/signup/slice';
import { forgotPasswordEmailRedux } from '../../../redux/signup/selectors';
import GoBackButton from '../../../Common/Components/GoBackButton';

const ForgotPasswordSuccessComponent = () => {
  const VERIF_MAIL_INTERVAL = 60;
  const [calculateTime, setCalculateTime] = useState(true);
  const [showTime, setShowTime] = useState(true);
  const [timer, setTimer] = useState('');
  const [isDisabled, setDisabled] = useState(true);
  const [resendClicked, setResendClicked] = useState(false);

  const dispatch = useDispatch();
  const forgotPasswordemail = useSelector(forgotPasswordEmailRedux);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { t } = useTranslation(['forgotPassword']);

  useInterval(() => {
    if (calculateTime) {
      const initDate = localStorage.getItem('forgotPasswordMailSent');
      const msecs = new Date() - new Date(initDate);
      const remaining = VERIF_MAIL_INTERVAL - Math.ceil(msecs / 1000);
      setTimer('0:' + (remaining < 10 ? '0' : '') + String(remaining));
      if (remaining <= 0) {
        setCalculateTime(false);
        setDisabled(false);
        setTimer('');
        setShowTime(false);
      }
    }
  }, 500);

  const handleResendClicked = async () => {
    if (!isDisabled) {
      if (!executeRecaptcha) {
        console.error('Execute recaptcha not yet available');
        return;
      }

      const token = await executeRecaptcha('forgotPassword');

      const data = {
        email: forgotPasswordemail,
        reCaptchaToken: token
      };
      const resp = await dispatch(forgotPasswordAction(data));
      if (resp.meta.requestStatus === 'fulfilled') {
        localStorage.setItem('forgotPasswordMailSent', new Date());
        setResendClicked(true);
        setShowTime(true);
        setDisabled(true);
        setCalculateTime(true);
      } else {
        //   alert('Error');
      }
    }
  };

  const handleBackClicked = async () => {
    await dispatch(setForgotPasswordStep(1));
  };

  const Back = () => {
    return (
      <div className={styles.back}>
        <Link to={route.login} style={{ display: 'flex' }}>
          <Arrow className={styles.arrow} />
          <div className={styles.BackText} onClick={handleBackClicked}>
            {t('backLogin')}
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.outerCard}>
          <div className={styles.innerCard}>
            <CoensioLogo className={styles.logo} />
            <div className={styles.backWrapper}>
              <GoBackButton
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  history.back();
                }}
              />
              <label className={styles.cardTitle}>{t('sentMail')}</label>
            </div>
            <label className={styles.cardText}>{t('checkInbox')}</label>
            <text className={styles.resendWrapper}>
              <span>
                <Trans i18nKey="didntReceiveMail" ns="forgotPassword" values={{ timer: timer }}>
                  0<span className={styles.cardSubText}>1</span>
                  <span
                    onClick={handleResendClicked}
                    className={styles.link}
                    style={{
                      pointerEvents: isDisabled ? 'none' : 'inherit',
                      opacity: isDisabled ? '0.4' : '1'
                    }}
                  >
                    2
                  </span>
                </Trans>
                {showTime && (
                  <Trans
                    i18nKey="didntReceiveMailTime"
                    ns="forgotPassword"
                    values={{ timer: timer }}
                  >
                    0<span className={styles.remaining}>1</span>
                  <span className={styles.remaining}>2</span>
                  </Trans>
                )}
              </span>
            </text>
          </div>
          <InfoBanner
            setStatus={setResendClicked}
            show={resendClicked}
            text={t('resent')}
            textColor="#4AC389"
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccessComponent;
