import ReactGA from 'react-ga4';

export const initGA = () => {
  ReactGA.initialize('G-2T5J5Q0E6D'); // İzleme kimliğinizi ekleyin
};

// Event Takibi
export const trackEvent = ({ category, action, label }) => {
  ReactGA.event({
    category,
    action,
    label
  });
};
