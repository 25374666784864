import React from 'react';

const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
};
const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const modalContainerStyle = {
    width: '100%',
    maxWidth: '800px',
    padding: '24px',
    background: 'linear-gradient(to bottom right, #fafafa, #ffffff)',
    borderRadius: '10px',
    border: '1px solid #ccc',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};
const modalContentStyle = {
    width: '600px',          
    maxWidth: '90%',         
    maxHeight: '80vh',       
    overflowY: 'auto',       
    background: '#fff',      
    borderRadius: '8px',
    padding: '20px',
    position: 'relative',    
};

const closeButtonContainerStyle = {
    position: 'absolute',
    top: '8px',
    right: '8px',
    cursor: 'pointer',
};

const lowQualityHeaderStyle = {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom:'10px',
    gap: '12px',
    backgroundColor: '#ffe9e9',
    padding: '12px',
    borderRadius: '8px',
    borderLeft: '6px solid #f44336',
};

const categoryStyle = {
    background: 'linear-gradient(to bottom right, #e7f0ff, #f3f8ff)',
    border: '1px solid #8eb7f3',
    borderRadius: '8px',
    padding: '12px',
    flex: 1,
};

const difficultyStyle = {
    background: 'linear-gradient(to bottom right, #f8efff, #fcf9ff)',
    border: '1px solid #b28cf2',
    borderRadius: '8px',
    padding: '12px',
    flex: 1,
};

const suggestionBoxStyle = {
    backgroundColor: '#f7f7f7',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '12px',
};

const suggestedOptionsListStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
};

const optionStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    borderRadius: '8px',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
};

const correctOptionStyle = {
    ...optionStyle,
    backgroundColor: '#e8fbee',
    border: '1px solid #9ed9a9',
};

const buttonRowStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    borderTop: '1px solid #eee',
    paddingTop: '16px',
    marginTop: '16px',
};

const buttonStyle = {
    padding: '8px 16px',
    borderRadius: '6px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '14px',
};

const buttonSecondaryStyle = {
    ...buttonStyle,
    backgroundColor: '#eee',
    color: '#333',
};

const buttonPrimaryStyle = {
    ...buttonStyle,
    backgroundColor: '#2196f3',
    color: '#fff',
};

const loadingErrorContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
};

const loadingTextStyle = {
    color: '#666',
};

const errorTextStyle = {
    color: 'red',
};

const highQualityContainerStyle = {
    ...modalContainerStyle,
    backgroundColor: '#f9fff9',
    borderLeft: '6px solid #4caf50',
};

const highQualityHeaderStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    alignItems: 'flex-start',
    padding: '16px',
    backgroundColor: '#ecfaec',
    borderRadius: '8px',
};

const AnalysisModal = ({
    isOpen,
    onClose,
    analysis,
    onUpdate,
    onSaveWithoutUpdate,
    isLoading,
}) => {
    if (!isOpen) return null;

    // Loading State
    if (isLoading) {
        return (
            <div style={modalOverlayStyle}>
                <div style={modalContainerStyle}>
                    <div style={loadingErrorContainerStyle}>
                        <span style={loadingTextStyle}>Yükleniyor...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (!analysis) {
        return (
            <div style={modalOverlayStyle}>
                <div style={modalContainerStyle}>
                    <div style={loadingErrorContainerStyle}>
                        <span style={errorTextStyle}>Bir hata oluştu</span>
                    </div>
                </div>
            </div>
        );
    }

    const quality = parseInt(analysis.questionQuality, 10);

    return (
        <div style={modalOverlayStyle}>
            {quality >= 70 && !analysis.comment ? (
                <HighQualityContent onClose={onClose} quality={quality} />
            ) : (
                <LowQualityContent analysis={analysis} onUpdate={onUpdate} onClose={onClose} onSaveWithoutUpdate={onSaveWithoutUpdate} />
            )}
        </div>
    );
};


const LowQualityContent = ({
    analysis,
    onUpdate,
    onClose,
    onSaveWithoutUpdate,
}) => {
    const {
        questionQuality,
        suggestion,
        comment,
        correctCategory,
        correctDifficulty
    } = analysis;

    return (
        <div style={modalContentStyle}>
            <div style={closeButtonContainerStyle} onClick={onClose}>
                <img src="/static/media/largeCloseButton.05fb58f0775637fd93150caed07c5029.svg" alt="close" width={24} height={24} />
            </div>

            <div style={lowQualityHeaderStyle}>
                <div style={{ fontSize: '24px', color: '#f44336' }}>⚠️</div>
                <div>
                    <h2 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold', color: '#f44336' }}>
                        Düşük Soru Kalitesi: {questionQuality}
                    </h2>
                    <p style={{ margin: 0, marginTop: '4px', color: '#e53935', fontSize: '14px' }}>
                        {comment}
                    </p>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div style={{ display: 'flex', gap: '16px' }}>
                    <div style={categoryStyle}>
                        <h3 style={{ margin: 0, marginBottom: '4px', fontSize: '16px', color: '#1565c0' }}>
                            Önerilen Kategori
                        </h3>
                        <p style={{ margin: 0, color: '#333' }}>{correctCategory}</p>
                    </div>
                    <div style={difficultyStyle}>
                        <h3 style={{ margin: 0, marginBottom: '4px', fontSize: '16px', color: '#6a1b9a' }}>
                            Önerilen Zorluk
                        </h3>
                        <p style={{ margin: 0, color: '#333' }}>{correctDifficulty}</p>
                    </div>
                </div>

                {/* Başlık ve Soru */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    <div style={suggestionBoxStyle}>
                        <h3 style={{ margin: 0, marginBottom: '4px', fontSize: '16px', color: '#555' }}>
                            Önerilen Başlık
                        </h3>
                        <p style={{ margin: 0, color: '#111' }}>{suggestion?.suggestionTitle}</p>
                    </div>
                    <div style={suggestionBoxStyle}>
                        <h3 style={{ margin: 0, marginBottom: '4px', fontSize: '16px', color: '#555' }}>
                            Önerilen Soru
                        </h3>
                        <p style={{ margin: 0, color: '#111' }}>{suggestion?.suggestionQuestion}</p>
                    </div>
                </div>

                {/* Seçenekler */}
                {suggestion?.suggestionOptions && (
                    <div style={suggestionBoxStyle}>
                        <h3 style={{ margin: 0, marginBottom: '8px', fontSize: '16px', color: '#555' }}>
                            Önerilen Seçenekler
                        </h3>
                        <div style={suggestedOptionsListStyle}>
                            {suggestion.suggestionOptions.map((option, index) => {
                                const isCorrect = suggestion.suggestionCorrectOption === index;
                                return (
                                    <div key={index} style={isCorrect ? correctOptionStyle : optionStyle}>
                                        <span style={{ flex: 1 }}>{option}</span>
                                        {isCorrect && (
                                            <span style={{ marginLeft: '12px', padding: '4px 8px', backgroundColor: '#c8f7d8', color: '#2e7d32', fontSize: '12px', borderRadius: '4px', }}>
                                                Doğru Cevap
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>

            {/* Action Buttons */}
            <div style={buttonRowStyle}>
                <button type="button" onClick={onSaveWithoutUpdate} style={buttonSecondaryStyle}>
                    Soruyu Güncellemeden Kaydet
                </button>
                <button type="button" onClick={onUpdate} style={buttonPrimaryStyle}>
                    Önerileri Uygula
                </button>
            </div>
        </div>
    );
};


const HighQualityContent = ({ quality, onClose }) => {
    return (
        <div style={modalContentStyle}>
             <div style={closeButtonContainerStyle} onClick={onClose}>
                <img src="/static/media/largeCloseButton.05fb58f0775637fd93150caed07c5029.svg" alt="close" width={24} height={24} />
            </div>
            <div style={highQualityContainerStyle}>
                <div style={highQualityHeaderStyle}>
                    <div style={{ fontSize: '24px', color: '#4caf50' }}>✓</div>
                    <div>
                        <h2 style={{ margin: 0, fontSize: '18px', fontWeight: 'bold', color: '#388e3c' }}>
                            Soru Kalitesi: {quality}/100
                        </h2>
                        <p style={{ margin: 0, marginTop: '4px', color: '#4caf50', fontSize: '14px' }}>
                            Sorunuz yüksek kalite standartlarını karşılıyor.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalysisModal;
