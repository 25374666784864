import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './InviteComponent.module.css';
import { ReactComponent as CoensioLogo } from '../../../images/Common/Coensio_newLogo.svg';
import GoBackButton from '../../../Common/Components/GoBackButton';
import { trackEvent } from '../../../utils/analytics';

const InviteComponent = () => {
  const history = useHistory();
  const { t } = useTranslation(['signup']);

  useEffect(() => {
    trackEvent({
      category: 'Request Demo',
      action: 'request_demo',
      label: 'Request Demo'
    });
  }, []);

  return (
    <div className={styles.PageWrapper}>
      <div className={styles.CardWrapper}>
        <div className={styles.Card}>
          <div>
            <CoensioLogo className={styles.Logo} />
          </div>

          <div className={styles.CardTextBox}>
            <div className={styles.backWrapper}>
              <GoBackButton
                style={{ marginLeft: '-10px' }}
                onClick={() => {
                  history.push(`/login`);
                }}
              />
              <div className={styles.CardTitle}>{t('receivedRequest')}</div>
            </div>

            <div className={styles.CardText}>{t('informedTeam')}</div>
            <Link to="/login" className={styles.returnLink}>
              {t('returnCoensio')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteComponent;
